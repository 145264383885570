/*
 * Import the SDK and Project Configuration
 */
import AWS from 'aws-sdk';
import awsmobile from './aws-exports';

/*
 * Configure the SDK to use anonymous identity 
 */
// AWS.config.update({
//   region: awsmobile.aws_cognito_region,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
//   })
// });

var albumBucketName = 'rksamajam-photos';
var bucketRegion = 'us-west-2';
var IdentityPoolId = 'us-west-2:840d1a2b-781d-49f5-802f-f61e6893a0f3';

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
});

var s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: {Bucket: albumBucketName}
});

window.listAlbums = function () {
    var albumListArr = new Array();
    s3.listObjects({Delimiter: '/'}, function(err, data) {
      if (err) {
        //return alert('There was an error listing your albums: ' + err.message);
      } else {
        var albums = data.CommonPrefixes.reverse().map(function(commonPrefix) {
          var prefix = commonPrefix.Prefix;
          var albumName = decodeURIComponent(prefix.replace('/', ''));
          return window.getHtml([
            //'<li>',
              window.viewAlbum(albumName),
            //'</li>'
          ]);
        });
        var htmlTemplate = [
            window.getHtml(albums)
        ]
        if(document.getElementById('app') != undefined){
          document.getElementById('app').innerHTML = window.getHtml(htmlTemplate);
        }
      }
    });
  }

  window.viewAlbum = function (albumName) {
    var albumPhotosKey = encodeURIComponent(albumName) + '//';
    s3.listObjects({Prefix: albumPhotosKey}, function(err, data) {
      if (err) {
        //return alert('There was an error viewing your album: ' + err.message);
      }
      // 'this' references the AWS.Response instance that represents the response
      var href = this.request.httpRequest.endpoint.href;
      var bucketUrl = href + albumBucketName + '/';
  
      var photos = data.Contents.map(function(photo) {
        var photoKey = photo.Key;
        var photoUrl = bucketUrl + encodeURIComponent(photoKey);
        return window.getHtml([
          '<img style="width:400px;height:200px;" src="' + photoUrl + '"/>',
          '<span style="padding-left:10px">&nbsp;</span>'
        ]);
      });
      
      var htmlTemplate = [
        '<h2 class="Data_Central_color1">',
          'Album: ' + albumName,
        '</h2>',
        
        '<div>',
          window.getHtml(photos),
        '</div>'
      ]
      if(document.getElementById('app') != undefined){
        document.getElementById('app').innerHTML = document.getElementById('app').innerHTML + window.getHtml(htmlTemplate);
      }
    });
  }

  window.getHtml = function (template) {
    return template.join('\n');
 }