import React, { Component } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { TextField } from '@material-ui/core';
import ReactPlayer from 'react-player'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

//function Posts(props) {
class Posts extends Component {  

  constructor(props){
      super(props)
      this.state = {
          appConstants: window.appConstants,
          posts: window.posts
      }
    // window.getPosts()
  }  

  render() {
    var posts = this.state.posts;
    return (
      <div>
        <Grid
          container
          spacing={0}
          // direction="column"
          // alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}>

          <Grid item xs={3}>
            <br/>
            <TextField color="secondary" id="outlined-basic" label="Search Video(s)" variant="outlined" />
            
            <Button variant="contained" color="primary">
              Search
            </Button>
          </Grid>   

        
        <div style={{ marginTop: 20, padding: 30 }}>
          <Grid container spacing={40} justify="center">
            {
              posts.map(post => (
              <Grid item key={post.title}>
                <Card>
                  <CardActionArea>
                    <ReactPlayer
                      width='440px'
                      height='140px'
                      controls
                      url={post.link}
                      onReady={() => console.log('OnReady CallBack')}
                      onStart={() => console.log('OnReady CallBack')}
                      onPause={() => console.log('OnReady CallBack')}
                      onEnded={() => console.log('OnReady CallBack')}
                      onError={() => console.log('OnReady CallBack')}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {post.title}
                      </Typography>
                      <Typography component="p">{post.excerpt}</Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      Share
                    </Button>
                    <Button size="small" color="primary">
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          </div>
        
        <Accordion>
              <AccordionItem>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                          Previous Videos/Links
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  
                  <AccordionItemPanel>
                  <div id="content1">
                    <div styleName="display:block;">
                        <div styleName="display:block;">
                            <div
                                styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                <b styleName="font-family: georgia, 'nimbus roman no9 l', serif;">
                                    <b styleName="line-height: 22px; font-family: georgia, 'nimbus roman no9 l', serif;">
                                        <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                    href="https://www.youtube.com/watch?v=ol8w9XIB5IQ" target="_blank">Papansam Sri
                                                    Sivam Keerthanams Yuva Bhajan Mela Trust, Palakkad</a><br/> <br/>
                                                <b styleName="line-height: 22px; font-family: georgia, 'nimbus roman no9 l', serif;">
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="https://www.youtube.com/playlist?list=PL1sFFfpPjmz_FjfYtu0VdJeEfR4ZQtZpj&amp;feature=em-share_playlist_user"
                                                                target="_blank">Radha kalyanam @ Kansas - Aug 2014</a><br/>
                                                            <br/>
                                                            Courtesy:&nbsp;https://www.ustream.tv/channel/bhagavatham<br/>
                                                            <br/>
                                                            <b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;">Individual Links
                                                                            Below:</span></font>
                                                                </b></b><br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=UsHwBSz5fjQ"
                                                                        target="_blank">Thodaya mangalam, guru keerthanai, Ashtapadhi
                                                                        1st session</a></span></font><br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=K6H471XZ71s"
                                                                        target="_blank">Ashtapadhi - Hey nandana, vanamali vasudeva
                                                                        jagan mohana</a></span></font><br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><br/>
                                                                    <a href="https://www.youtube.com/watch?v=l2y80Hp7aoo"
                                                                        target="_blank">Ashtapadhi 3rd session&nbsp;</a></span></font>
                                                            <br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=sYlVM-xrOnE"
                                                                        target="_blank">Ashtapadhi 3rd session - Krishna murali
                                                                        gopala</a></span></font><br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=WQc43E9I0fA"
                                                                        target="_blank">Ashtapadhi 3rd session - &nbsp;Oh rama nee
                                                                        namam, Jaya janaki , Atma rama ananda ramana</a></span></font>
                                                            <br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=c9wUdeET32U"
                                                                        target="_blank">Ashtapadhi 3rd session - Chethashri ramam,
                                                                        Sakala loka nayike, Charanaagatha vathsala,
                                                                        Upacharamu</a></span></font><br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="http://youtu.be/De6GQumTjXQ" target="_blank">Divyanamam
                                                                        1st session</a></span></font><br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><br/>
                                                                </span></font>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="http://youtu.be/77kl1o29Y8Q" target="_blank">Divyanamam
                                                                        2nd session - kamala vallabha, krishna krishna, aloka
                                                                        tulasi</a></span></font><br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=ds5HPAB3a6w">Murahara ,
                                                                        yelello, namacha bazaar</a>&nbsp;</span></font><br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="https://www.youtube.com/watch?v=5xHYyRyGenA"
                                                                        target="_blank">saasasathvaja sanandam, radhey radhey radhey
                                                                        govinda, radha kalyana vaibogame</a></span></font><br/>
                                                            <br/>
                                                            <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                        href="http://youtu.be/Incrsyz23So" target="_blank">Allo nere ,
                                                                        Suvvi, &nbsp;chitha juni jayamangalam, Viharathi haririha -
                                                                        jathi</a></span></font><br/>
                                                            <br/>
                                                            <a href="https://www.youtube.com/watch?v=DNFtPxyE-zg&amp;list=PL1sFFfpPjmz8lBjrr-h8wFSd3BIUTCNYT"
                                                                target="_blank">Sampradaya Bhajans - Meenakshi Kalyanam</a><br/>
                                                            <br/>
                                                            Individual Links Below:&nbsp;<br/>
                                                            <a href="https://www.youtube.com/watch?v=DNFtPxyE-zg" target="_blank">01
                                                                Slokam - Thodaya Mangalam (Sivan)</a><br/>
                                                            <a href="https://www.youtube.com/watch?v=KLDSKxo1MOo" target="_blank">02
                                                                Guru Keerthanai</a><br/>
                                                            <a href="https://www.youtube.com/watch?v=alcJHO6MJCU" target="_blank">03
                                                                Sivashtapadhi - Kalayanam</a><br/>
                                                            <b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;"><a
                                                                                href="https://www.youtube.com/watch?v=sFj9HKo9pyE"
                                                                                target="_blank">04 Sivashtapadhi - Kalayanam</a></span>
                                                                    </font>
                                                                </b></b><br/>
                                                            <b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;"><a
                                                                                href="https://www.youtube.com/watch?v=-ba6Tt7F9v0"
                                                                                target="_blank">05 Sivashtapadhi - Kalayanam</a></span>
                                                                    </font>
                                                                </b></b><br/>
                                                            <b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;"><a
                                                                                href="https://www.youtube.com/watch?v=nN8OhWQD8O4"
                                                                                target="_blank">06 Sivashtapadhi - Kalayanam</a></span>
                                                                    </font>
                                                                </b></b><br/>
                                                            <b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;"><a
                                                                                href="https://www.youtube.com/watch?v=_v3EAWj6SFY"
                                                                                target="_blank">07 Sivashtapadhi - Kalayanam</a></span>
                                                                    </font>
                                                                </b></b><br/>
                                                            <b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;"><a
                                                                                href="https://www.youtube.com/watch?v=_ogLEHp0q-g"
                                                                                target="_blank">08 Sivashtapadhi - Kalayanam</a></span>
                                                                    </font>
                                                                </b></b><br/>
                                                            <br/>
                                                            <a href="https://www.youtube.com/playlist?list=PL1sFFfpPjmz_4zQ30Sxtf53NnOohcR9NX"
                                                                target="_blank">Puri - Geetha Govinda Sapthagam - January 2014</a><br/>
                                                        </span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><b
                                                                styleName="font-family: georgia, 'nimbus roman no9 l', serif; line-height: 22px;"><b>
                                                                    <font color="#724634"><span
                                                                            styleName="line-height: 22.100000381469727px;">Individual Links
                                                                            Below:&nbsp;</span></font>
                                                                </b></b><br/>
                                                            <a href="http://youtu.be/Yf0rMkvL3L8" target="_blank">01
                                                                Introduction</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="https://www.youtube.com/watch?v=ZdwE1pEqBY0" target="_blank">02
                                                                Jai jai Ram Uynthathu uynthathu Padmavathy ramanam</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="https://www.youtube.com/watch?v=gOaUKfHO44c" target="_blank">03
                                                                Athaagathaaam - Radhey radhey radhey govinda</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="https://www.youtube.com/watch?v=lJWnV-RKny0" target="_blank">04 Na
                                                                ayathaha sakhi - Athagatha mabhi</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="https://www.youtube.com/watch?v=AfFgyU3p1PE" target="_blank">05
                                                                Thamatha manmatha kinna</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="http://youtu.be/EMEa4vnrYrk" target="_blank">06 Athranthare
                                                                Suchiram anunayena</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="http://youtu.be/m-F-nLbOrTE" target="_blank">07
                                                                Aaravali</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="http://youtu.be/nsOh_8_rPqQ" target="_blank">08 Saasasathvaja
                                                                sanandam Gathavathi Sakhi</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="http://youtu.be/qXISztsoaw0" target="_blank">09 Athagantham
                                                                prathi</a></span></font><br/>
                                                    <font color="#724634"><span styleName="line-height: 22.100000381469727px;"><a
                                                                href="http://youtu.be/p4E2aANyQ2s" target="_blank">10
                                                                Tharangam</a></span></font><br/>
                                                    <br/>
                                                    <a href="https://drive.google.com/folderview?id=0B0S2ZlXDN-CtQ25RR2FtbFdvdXc&amp;usp=sharing"
                                                        target="_blank">Navavarnam - Arcot Temple - &nbsp;2011</a><br/>
                                                    Ramani Bhagavathar - Karthick (Mrudangam) - Srivatsa (Dolki)<br/>
                                                    <br/>
                                                    <a href="https://www.youtube.com/playlist?list=PL1sFFfpPjmz_I24Oas94cHabqRJLh9mTz"
                                                        target="_blank">Ramani Bhavathar - Home Bhajan, Irving Tx - July 2014</a><br/>
                                                </b><br/>
                                                <div><br/>
                                                </div>
                                                <a href="Resources.html" target="_blank">Radhakalyanam by Srividhya Ganesh - Chicago
                                                    2013</a>
                                            </span></font>
                                    </b>
                                    <font color="#724634"><br/>
                                        <br/>
                                        <b styleName="font-family: georgia, 'nimbus roman no9 l', serif;"><a href="Resources.html"
                                                target="_blank">Ashtapadhi - 11/16/2013<br/>
                                                &nbsp;</a></b><br/>
                                        <span styleName="font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="https://www.youtube.com/watch?v=ldCEMuVNZj4" target="_blank">01 Thodaya
                                                Mangalam</a>&nbsp;</span><br/>
                                        <a href="http://youtu.be/Lx6dhfGZEVE" target="_blank">02 Sarvesham Sriguru Bodhendram</a><br/>
                                        <a href="http://youtu.be/5ZPqlIof4f4" target="_blank">03 Ashtapathi slokam Jayajagadhisha
                                            harey</a><br/>
                                        <a href="http://youtu.be/AjL3BCpNnQk" target="_blank">04 Ashtapadhi Viharathi Sancharath</a><br/>
                                        <a href="http://youtu.be/XqDutxF4sdY" target="_blank">05 Ashtapadhi Samsari Mamiyam
                                            ahomathura</a><br/>
                                        <a href="http://youtu.be/BMEBZgw_A0w" target="_blank">06 Ashtapadhi Avaso Radhika
                                            krishna</a><br/>
                                        <a href="http://youtu.be/Tw9Il08gTtw" target="_blank">07 Ashtapadhi Radharasikavara Athatham
                                            Nathaharey</a><br/>
                                        <a href="http://youtu.be/iHRcbGqW_BE" target="_blank">08 Ashtapadhi Athrantharey
                                            Kathithasamaye</a><br/>
                                        <a href="http://youtu.be/IK2mljiyVgI" target="_blank">09 Ashtapadhi Viharatha brindha
                                            namavali</a><br/>
                                        <a href="http://youtu.be/9-eiePr3DzE" target="_blank">10 Astapadhi Yahi madhava</a><br/>
                                        <a href="http://youtu.be/WDbhBOGJSsY" target="_blank">11 Astapadhi Vathasiyathi</a><br/>
                                        <a href="http://youtu.be/vjSTD3i6_lU" target="_blank">12 Astapadhi Aravali Manjuthara</a><br/>
                                        <a href="http://youtu.be/kkoLAxKqHdc" target="_blank">13 Astapadhi Kuruyathu nandana
                                            Tharangam</a><br/>
                                        <a href="http://youtu.be/GXxueEi8r-8" target="_blank">14 Kasthurigana I</a><br/>
                                        <a href="http://youtu.be/2mZ_BAKK7T0" target="_blank">15 Kasthurigana II Day I
                                            completion</a><br/>
                                        <br/>
                                        <b styleName="font-family: georgia, 'nimbus roman no9 l', serif;"><a href="Resources.html"
                                                target="_blank">Radhakalyanam - 11/17/2013</a></b><br/>
                                        <br/>
                                        <a href="http://youtu.be/1X4OG6fVOTk" target="_blank">01 Narayanam Bhaje guru mouli mane</a><br/>
                                        <a href="http://youtu.be/nkwceLdHJpk" target="_blank">02 Viraha pandu Samuditha vachane</a><br/>
                                        <a href="http://youtu.be/Zyb0Wv1bUxI" target="_blank">03 kalaya kalyani narayana</a><br/>
                                        <a href="http://youtu.be/x7BjCCYlLzM" target="_blank">04 Raghava rama</a><br/>
                                        <a href="http://youtu.be/L5nnNRAFjEQ" target="_blank">05 Krishna rama Dekhila dekhila</a><br/>
                                        <a href="http://youtu.be/qN_GLpdgE1M" target="_blank">06 Dasharatha rama NEela megha</a><br/>
                                        <a href="http://youtu.be/Y32bWL-ZoXQ" target="_blank">07 Madumeikkum kanne Chalangai
                                            katti</a><br/>
                                        <a href="http://youtu.be/xHFV6xNOY0w" target="_blank">08 Nandatmaja Madhava govindha</a><br/>
                                        <a href="http://youtu.be/qkY4S-2tpVM" target="_blank">09 Devadeva devaki srimurali sridhara
                                            Gopika geetham</a><br/>
                                        <a href="http://youtu.be/bG-SP-gpFXU" target="_blank">10 Natawaralal Yelelo</a><br/>
                                        <a href="http://youtu.be/wqE20EtUlUU" target="_blank">11 Namacha bazar Rangamma</a><br/>
                                        <a href="http://youtu.be/egpOpoy_h00" target="_blank">12 Veda veda Taaro jagannatha
                                            mangalam</a><br/>
                                        <a href="http://youtu.be/xJS93vESCY4" target="_blank">13 Radhavadhana Krishna krishna</a><br/>
                                        <a href="http://youtu.be/YUReGQ4XLLY" target="_blank">14 Srikrishna jaya jaya allo nere dillo
                                            Krishna krishna</a><br/>
                                        <a href="http://youtu.be/l5uFr40Rl0s" target="_blank">15 Lalitha lavanga Shobaane
                                            Churnikai</a><br/>
                                        <a href="http://youtu.be/gDWX-sr7KW8" target="_blank">16 Laganashtagam Jaya radhe jaya</a><br/>
                                        <a href="http://youtu.be/cEU1bpBHioc" target="_blank">17 Radharasikavara Priya radhe</a><br/>
                                        <a href="http://youtu.be/8SqwCv19K4A" target="_blank">18 Gandhamu Tu tu tu Ra ra ra
                                            Charanambu</a><br/>
                                        <a href="http://youtu.be/mTAET93dSlc" target="_blank">19 Pula chanda laali raadhe raadhe</a><br/>
                                        <br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><b><a
                                                        href="https://docs.google.com/folder/d/0B0S2ZlXDN-CtQzl0TV9VWHpzQ3c/edit"
                                                        target="_blank">Vasantham Mahal - Sashtiapdhapurthi</a></b></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">01 Thodaya Mangalam - Dakshinamurthy Guru keerthanai</span>
                                        </font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">02 Thirukumaranai avatharithaar</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">03 Sriguru Bodhendram - Gurumurthy Padamule</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">04 Athade Parabrahmam</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">05 Sadguru Gyanananda</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">06 Abhangam - Gurukrupa Anjane</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">07 Abhangam - Morilaagi lagan</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">08 Ashtapadi - Radha Vadhana</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">09 Tharangam - Alokaye Rukmini</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">10 Dasaratha rama govinda</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">11 Speech</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">12 Baghyada lakshmi - second half only</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">13 Sabapathikku Veru Deivam - Jai jai seetha ram - Jaaya
                                                jaaya thu pandari (abhangam)</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">14 Abhangam - Namacha Bazaar</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">15 Abhangam - Hey Dayalu</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">16 Saari bandhane - Karpagame kanpaarai - Bavamaana</span>
                                        </font><br/>
                                        <br/>
                                        <font color="#724634" styleName="font-weight: normal; font-size: 18px;"><span
                                                styleName="line-height: 22px;"><b>Krishna gana sabha 2012</b></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=MIFgE04d8Ic"
                                                    target="_blank">https://www.youtube.com/watch?v=MIFgE04d8Ic</a></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634" styleName="font-weight: normal; font-size: 11px;"><span
                                                styleName="line-height: 22px;"><b>1) Thodaya Mangalam</b></span></font><br/>
                                        <br/>
                                        <font face="georgia, nimbus roman no9 l, serif" styleName="font-weight: normal;">
                                            <a href="https://www.youtube.com/watch?v=1wYiHwsn2DY"
                                                target="_blank">https://www.youtube.com/watch?v=1wYiHwsn2DY</a></font><br/>
                                        <br/>
                                        <b styleName="font-weight: normal;">
                                            <font styleName="font-size: 11px;">
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        chitram vatatharo muley - Dakshinasasyam gurum vandhe</span></font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        &nbsp;Namavali - sambho murthim adhi gurum</span></font><br/>
                                                <font styleName="font-size: 11px;" face="georgia, nimbus roman no9 l, serif">
                                                    2) Muthakarena - Bajare loka gurum manuja</font><br/>
                                            </font>
                                        </b>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=wqiSVIXzA2E"
                                                    target="_blank">https://www.youtube.com/watch?v=wqiSVIXzA2E</a></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font><b styleName="font-weight: normal;">
                                            <font styleName="font-size: 11px;">
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        Bajare loka gurum manuja - contd</span></font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        &nbsp;Namavali - Sankara guro</span></font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    2) Kaashaya thandakara - bhajare manasa bodhera yogindram</font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        &nbsp;Namavali - gurunatha gurunatha bodhendra sadgurunatha</span></font><br/>
                                                <font _face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    3) Srikantamiva pasvantham</font>
                                            </font>
                                            <font face="georgia, nimbus roman no9 l, serif">&nbsp;</font><br/>
                                        </b>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=s0JfHEwT-78"
                                                    target="_blank">https://www.youtube.com/watch?v=s0JfHEwT-78</a></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font styleName="font-weight: normal; font-size: 11px;"><b>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        Srikantamiva pasvantham - contd</span></font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        &nbsp;sitharum sachitananda desika chinmaya - Omgurunatha Jayagurnatha</span>
                                                </font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">2) Yasya
                                                        unchavruthi samaye - chintayare sadgurum</span></font>
                                            </b></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=2rlxQzZuyjc"
                                                    target="_blank">https://www.youtube.com/watch?v=2rlxQzZuyjc</a></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font styleName="font-weight: normal; font-size: 11px;"><b>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        chintayare sadgurum - contd</span></font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    2) Guru matha guru pitha</font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        Namavali - sadguru jayagur sachitananda guru</span></font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    3) Santha mahantho - Pavithradeha vani punya vantham</font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        &nbsp;Namavali - Gyaneshwara mauli guru gyana raya mauli</span></font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    4) oothukadu intro</font>
                                            </b></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=7uMruvV5bfI"
                                                    target="_blank">https://www.youtube.com/watch?v=7uMruvV5bfI</a></span></font><br/>
                                        <br/>
                                        <font styleName="font-weight: normal; font-size: 11px;"><b>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        Oothukadu - speech</span></font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    2) Hari hara bedha - Namami gyananandha gurum</font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    3) Bhagavathas felicitation</font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    4) Ashtapadhi slokas - Padmavathy ramanam</font>
                                            </b></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=5dQE0HmrywA"
                                                    target="_blank">https://www.youtube.com/watch?v=5dQE0HmrywA</a></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                        <font styleName="font-weight: normal; font-size: 11px;"><b>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        Padmavathy ramanam - contd</span></font><br/>
                                                <font face="georgia, nimbus roman no9 l, serif" styleName="font-size: 11px;">
                                                    2) Anekanaari - Haririha mukta vadhunikare</font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">&nbsp;
                                                        &nbsp;Namavali - Radhey radhey govindha</span></font><br/>
                                            </b></font>
                                        <br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="https://www.youtube.com/watch?v=JoVMpTI_YGk"
                                                    target="_blank">https://www.youtube.com/watch?v=JoVMpTI_YGk</a></span></font><br/>
                                        <br/>
                                        <font styleName="font-weight: normal; font-size: 11px;"><b>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">1)
                                                        Yathangri pange - sloka</span></font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">2) Saari
                                                        bandhane</span></font><br/>
                                                <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;">3)
                                                        Navaneetha chora - kayena vacha</span></font>
                                            </b></font><br/>
                                        <br/>
                                        <a href="https://docs.google.com/folder/d/0B0S2ZlXDN-CtY2dGeWZmMnNpV3M/edit" target="_blank"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;">Tiruppugazh
                                            Bhajan at Sri Ganesa Vadhyar 19 Nov 2012</a><br/>
                                        <br/>
                                        <b><a href="https://www.youtube.com/watch?v=JUD7-CfHtsU" target="_blank">Ramani Bhagavathar @
                                                Pozhichalur 2010</a></b><br/>
                                        <br/>
                                        <b styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;">
                                            <font styleName="font-size: 16px;">Radhakalyanam 2012 @ Chicago - By SriVidhya Ganesh</font>
                                        </b><br/>
                                        <br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="http://youtu.be/y33lYet9gkk" target="_blank">01
                                                    Namavali - Gurumouli Maney - Sadguruney Maja &nbsp;</a></span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="http://youtu.be/0SQ-c58wx18" target="_blank">02
                                                    Ashtapathi slokams - Srithakamalakucha</a> &nbsp;</span></font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/g7mNjfh1Zec" target="_blank">03 Neelamegha Shyama Part 1
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/7M3bySePXdY" target="_blank">04 Neelamegha Shyama Part 2
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/STMesU_nirc" target="_blank">05 Ashtapadhi Radhika krishna -
                                                Radharasikavara &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/U5ncC3LzhaA" target="_blank">06 Ashtapadhi Nathaharey - Srimurali
                                                sreedhara &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/asDJq9RVlZo" target="_blank">07 Astapadhi Samuthadita vachane
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/F1D7f1KtpLI" target="_blank">08 Ashtapadhi Charuseeley
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/4xG2EoKeVcE" target="_blank">09 Hey Vishwabala Gopala
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/mZCqya-B-ZI" target="_blank">10 Kalaya Kalyani Part 1
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/DcwciKmMggU" target="_blank">11 Kalaya Kalyani Part 2
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/sQxY6QNfDTM" target="_blank">12 Divyanamam 1</a> &nbsp;</span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/J7ha9wCMr1E" target="_blank">13 Divyanamam 2 &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/hO_Zhl6PSMY" target="_blank">14 Divyanamam 4 &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/JyR_Z3B_m8Q" target="_blank">15 Divyanamam 5 Part 1
                                                &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/nUysBxgvw9c" target="_blank">16 Divyanamam 6 Part 2</a>
                                            &nbsp;</span><br/>
                                        <br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/ddmKLiMNq4c" target="_blank">17 Divyanamam 7 &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/--3Q-0pPuQA" target="_blank">18 Divyanamam 8 &nbsp;</a></span><br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;">&nbsp;</span></font><br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/5vPZNdK_Vtw" target="_blank">19 Divyanamam 9 </a>&nbsp;</span><br/>
                                        <br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/_66fSd_QITw" target="_blank">20 Divyanamam 10 Part 1
                                                &nbsp;</a></span><br/>
                                        <br/>
                                        <font color="#724634"
                                            styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><a href="http://youtu.be/U5WrixPq5p8" target="_blank">21
                                                    Divyanamam 11 Part 2 &nbsp;</a></span></font><br/>
                                        <br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/BupgNcNsbzY" target="_blank">22 Divyanamam 12
                                            </a>&nbsp;</span><br/>
                                        <br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/epfijL7Z1FE" target="_blank">23 Divyanamam 13
                                                &nbsp;</a></span><br/>
                                        <br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/L1iugEgO2AE" target="_blank">24 Divyanamam 14</a>
                                            &nbsp;</span><br/>
                                        <br/>
                                        <span styleName="font-weight: normal; font-family: georgia, 'nimbus roman no9 l', serif;"><a
                                                href="http://youtu.be/9aU8d--edpA">25 Divyanamam 15 &nbsp;</a></span><br/>
                                        <br/>
                                        <br/>
                                    </font>
                                </b></div><b styleName="font-family: georgia, 'nimbus roman no9 l', serif;">
                                <font color="#724634">
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        "<strong>Naadhabrahmam Music Festival</strong>"@ Dakshinamurthy Auditorium - Rare krithis of
                                        Oothukadu Mahakavi Venkata subbiar (12/20/2011)</div>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                    </p>
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=UeLbLRDuAYg" target="_blank">01 Omkara chithha -
                                            Govinda govinda - speech</a> </div>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=yr3Ixz48lOA" target="_blank">02 Meividathu -
                                            Satyam param dhemahe</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=_pe_bDACf0U" target="_blank">03 Uynthathu
                                            uynthathu</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=LIFbFl3GpHc" target="_blank">04 Vrajajana
                                            kulapaalam - Koduthuvaitha punniyavan</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="http://youtu.be/icPcjumGvQs" target="_blank">05 Raasa keyli - govinda radhey
                                            (namavali)</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=m_gNrU1_D54" target="_blank">06 vandarntha kuzhal
                                            - chora chathurya leela</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=P9WusqNJ960" target="_blank">07 sonnathai kel
                                            kanna</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="http://youtu.be/jogewP_cwVo" target="_blank">08 Maninoopura thaari - pakkavathyam
                                            intro</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="http://youtu.be/jCzMXVINLAE" target="_blank">09 petrai thavam - ninringu
                                            arulkaatu</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=Vr6t-9ykBwk" target="_blank">10 nanmaiyaum
                                            selvamum part 1 slokam</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="http://youtu.be/3NRFetN1Omc" target="_blank">10 nanmaiyaum selvamum - Dasarathey
                                            daya - sonnal mangalam - kayenavacha</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<br/>
                                        <strong>Oothukaadu Navavarnam - November 2011 </strong>(Audio)</p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        <font styleName="font-size: 11px;"><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MGRlZDZhOTRjNGFiYWIwNmJkOWIxNTExMDY3MDdlNDc6Ojo6MDFfU3JpR2FuZXNod2FyYS5tcDM="
                                                        target="_blank">&nbsp;01 Sri Ganeshwara</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MTEyZDA4ZjUzOTBiMzM3OGYxN2UxZDE2OTQ1NjhkMTg6Ojo6MDJfVmFuY2hhc2lZYXRoaS5tcDM="
                                                        target="_blank">&nbsp;02 Vanchasi Yathi</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MTRmNTgwZGU5MDZkMjAzNDczMWIyYTM1ODllMWNkOTk6Ojo6MDNfU2FudGhhdGhhbWFnYW0ubXAz"
                                                        target="_blank">&nbsp;03 Santhatham agam</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NzJlMzU4Y2E0YWEyYmZjNTc3ZTc1N2YyOGM5OGZiZDk6Ojo6MDRfQmhhamFzdmFzaVRyaXB1cmEubXAz"
                                                        target="_blank">&nbsp;4 Bhajasvasi Tripura</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YWI4MDdmZTExYzNjZDRmYTYxZGYzZDk0ZmZmMzU3Zjc6Ojo6MDVfU2FydmFqZWV2YWRheWEubXAz"
                                                        target="_blank">&nbsp;05 Sarvajeeva Dayapari</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6Y2Q0N2U3YmFiNGU0NGNhNTgzMTg3ZjUwZGUyOWU4ZmI6Ojo6MDZfWW9nYXlhZ2Vlc2h3YXJpX05hbWF2YWxpLm1wMw=="
                                                        target="_blank">&nbsp;06 Yogayogeshwari</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6Y2QxMTA3ZmFkODQ4YjMwOWI4NTNkMjkzMWMzMzRmNWY6Ojo6MDdfTmVlbGFsb2hpdGhhUmFtYW5pLm1wMw=="
                                                        target="_blank">&nbsp;07 Neelalohitharamani</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YWIzZmFhMTZhYWExMThhODMyNjE3ODRhM2U0OWFmNzM6Ojo6MDhfU2FkYWFuYW5kYW1heWkubXAz"
                                                        target="_blank">&nbsp;08 Sadaananda mayi</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmE0MjI5MTM2ZTVmZjI0NDdiYzM3NTY1MzNhMWI3MTk6Ojo6MDlfU2FrYWxhbG9rYW5heWlrZS5tcDM="
                                                        target="_blank">&nbsp;09 Sakalalokha nayike</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MWMwYzY0OTk3Yzg5OGU1ZGE4Zjc4NTBhOTZkY2FiNzc6Ojo6MTBfU2hhbmthcmlzcmlyYWphcmFqZXNod2FyaS5tcDM="
                                                        target="_blank">&nbsp;10 Shankari sri rajarajeshwari</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmJjMmU5NDc0NDRiYWI4YzZjODE5MmQyYmIyMGZmMDg6Ojo6MTFfTmF0aGFqYW5ha2FscGEubXAz"
                                                        target="_blank">&nbsp;11 Nathajana kalpa</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ZGRiOTUzMTg3ODI2ZjE1Yzc0MGJhY2EyOTYyYTVmNTc6Ojo6MTJfUmFqaVJhbWFuaV9QYXJhYXNoYWt0aUphbmFuaS5tcDM="
                                                        target="_blank">&nbsp;12 ParaShakti Janani - By Raaji Ramani</a></span></font>
                                            <br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MzEzY2U2ODJhZjkyYjk3OGMyMTRlYTE4ZmIxYTMwMTk6Ojo6MTNfSGlyYW5tYXlpTGFrc2htaS5tcDM="
                                                        target="_blank">&nbsp;13 Hiranmayi Lakshmi</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ZTk1YTRmNTQyZDkzY2UyZTFjNWUzYjk1NzYwMTVkNGI6Ojo6MTRfQW1iYU1hbmFta2FuaW50aHUubXAz"
                                                        target="_blank">&nbsp;14 Amba Manamkaninthu</a></span></font><br/>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><br/>
                                                </span></font>
                                            <font color="#724634" styleName="font-size: 11px;"><span styleName="line-height: 22px;"><a
                                                        href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ZmZiM2Y5OGNjNDU0OTBkYjhjZTAzMzAzMmQ1NTA4MDE6Ojo6MTVfSmF5YUpheWFTaGFrdGkubXAz"
                                                        target="_blank">&nbsp;15 Jaya Jaya Shakthi</a></span></font>
                                        </font><br/>
                                        <font color="#724634"
                                            styleName="font-weight: bold; font-family: georgia, 'nimbus roman no9 l', serif;"><span
                                                styleName="line-height: 22px;"><br/>
                                            </span></font>
                                    </p>
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        <strong><strong>Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram</strong><br/>
                                        </strong></div>
                                    <strong
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 1.6923076923076923;">
                                    </strong>
                                    <font color="#724634"
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 1.6923076923076923;">
                                        <span styleName="line-height: 1.6923076923076923;"><a
                                                href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MGRlZDZhOTRjNGFiYWIwNmJkOWIxNTExMDY3MDdlNDc6Ojo6MDFfU3JpR2FuZXNod2FyYS5tcDM="
                                                target="_blank"></a></span></font>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=2Ax6tpp8J0A&amp;feature=related"
                                            target="_blank">01 Guru Maatha guru pitha</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=1HdkExQpjus&amp;feature=mfu_in_order&amp;list=UL"
                                            target="_blank">02 Kayanai akayanaakkinai - nimmadhi adainthen</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=gdxA3wqHXwQ&amp;feature=related"
                                            target="_blank">03 Gurukrupa anjan</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=TqAGiSGYR9U&amp;feature=related"
                                            target="_blank">04 Azhumthorum anaikkum - yenna punniyam</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=0EWo_iXcTfs&amp;feature=related"
                                            target="_blank">05 Santha mahantho - Udara thumi santha</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=lNACoLTPkFU&amp;feature=related"
                                            target="_blank">06 Radhey Radhey Radhey Brundavana chanda</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=INluOyplhwk&amp;feature=related"
                                            target="_blank">07 Srimukha sarike</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=BkPiJ4JE_7M" target="_blank">08 Swami maje
                                            brahmachari</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<br/>
                                        <strong>Youtube Videos:</strong><a href="https://www.youtube.com/watch?v=xRxTg5Ixcao"
                                            target="_blank"><strong> </strong></a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;Excerpts from <strong>Seetha Kalyanam</strong> @ Sri Kunjumani Bhagavathar's home -
                                        05/27/2011</p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;Namavali - Guru - Ashtapadhi</p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=SYhcTtjpiSo" target="_blank">Jai jai ramakrishna
                                            hari</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=S7KV6SMz1wQ" target="_blank">Sarvesaam
                                            ashramaanam - Bhajare manasa</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=Zyj-bT68A74" target="_blank">Sandana sarchitha
                                            (4th asthapadi) kasthuri thilagam namavali</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=tj0vJypoDy4" target="_blank">Avaso priya saki -
                                            sthanavihithamapi (9th ashtapadi)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=DWIUxebsEJI" target="_blank">Athranthare -
                                            charuseeley - priya radhey (namavali) </a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=G88N9qsjG_c" target="_blank">Yathangri pange -
                                            Kankshey thava - govinda jai (namavali)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=FPOfZEEh8WI" target="_blank">Nanmayum selvamum -
                                            Dasaratha rama govinda - sri ramacharanam (namavali)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=xuaFRZEggMo" target="_blank">Dhyanavu kritha
                                            yugathi slokam </a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=1KX0ORhOc7I" target="_blank">Devaki nandana nanda
                                            muukunda</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        <a href="https://www.youtube.com/watch?v=qZixcbNiYA0" target="_blank">&nbsp;Mata ramo Matpita
                                            ramachandra - Jyachi vamsi kuladarma</a><br/>
                                        &nbsp;&nbsp;<br/>
                                        <br/>
                                        Poojai:</p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=Fxq2QYUh9t0" target="_blank">Baro murarey -
                                            kasthurigana chaara - sobane</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=CTv9XFb_-gA" target="_blank">Vedaa vedaa ho
                                            pandari</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=DeBIeyNiMq0" target="_blank">Upachaara keerthanai
                                            (karur mama)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=_KH7BwXutL0" target="_blank">Maje manuraatha
                                            (sruthi kumar)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=Mp52xxgtdEA" target="_blank">Jaya mahisasura
                                            marthini (Megha - Harini)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=noslcZapizk" target="_blank">Rama krishna (harini
                                            - megha)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                    </p>
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                    </div>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                    </p>
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        Seetha kalyanam:</div>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=_4mk9JzsB-M" target="_blank">Navavima manakamam -
                                            ramam parinaya (rama kalyanam ashtapadhi) </a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=fTRBWssCs-k" target="_blank">Suvvi Rama
                                            chithachuni - viharathi haririha - dehi mangalam</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=-7lBsNKrGIg" target="_blank">Dasaratha thanayudu
                                            rama</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=FAvTgwlxl3Y" target="_blank">Raghu nandana
                                            (namavali)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=kj5xUl0NVv0" target="_blank">Othividuthal -
                                            sathayai </a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=HlnlIhwRtp4" target="_blank">Seethapathi
                                            ramachandra ki - rama raghuvara namavali </a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=dWazoxWAQNg" target="_blank">Churnikai slokam -
                                            mangalya tharanam slokams -seetha rama (namavali)</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=w2SQLOZK8rk&amp;feature=related"
                                            target="_blank">Hey dayalu</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;Anjaneya Krithis:</p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=KApBU45l8Bc" target="_blank">swami maja -
                                            anajaneecha sutha</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=3DiTomj3kYc" target="_blank">Karpagame kanparaai
                                            - bhavamaana</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<strong>Samajam Video(s):</strong></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;Shemam Kuru Gopala (Tharangam / Narayana Theerthar): </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=OTIULOSu7dI"
                                            target="_blank">https://www.youtube.com/watch?v=OTIULOSu7dI</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<br/>
                                        Guru purandara dasa: <br/>
                                        <a href="https://www.youtube.com/watch?v=SE7YxwrIJsw"
                                            target="_blank">https://www.youtube.com/watch?v=SE7YxwrIJsw</a><br/>
                                        <br/>
                                        Sadguru vanchoni<br/>
                                        <a href="https://www.youtube.com/watch?v=agiM945YXVs"
                                            target="_blank">https://www.youtube.com/watch?v=agiM945YXVs</a><br/>
                                        <br/>
                                        Govinda Madhava (Namavali): </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a href="https://www.youtube.com/watch?v=_aGUHyiUeVs"
                                            target="_blank">https://www.youtube.com/watch?v=_aGUHyiUeVs</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;Koti Janma Punya<br/>
                                        <a href="https://www.youtube.com/watch?v=K_-HlbIp3eU"
                                            target="_blank">https://www.youtube.com/watch?v=K_-HlbIp3eU</a><br/>
                                        <br/>
                                        Yasyaangam kanakaaba - bhajare guru charanam<br/>
                                        <a href="https://www.youtube.com/watch?v=CaZGSiSZeMM"
                                            target="_blank">https://www.youtube.com/watch?v=CaZGSiSZeMM</a><br/>
                                        <br/>
                                        Ashtapadhi slokams<br/>
                                        <a href="https://www.youtube.com/watch?v=BIwjaVYGdwI"
                                            target="_blank">https://www.youtube.com/watch?v=BIwjaVYGdwI</a><br/>
                                        <br/>
                                        Ahamiha nivasami - Ashtapadhi 10<br/>
                                        <a href="https://www.youtube.com/watch?v=W0VUl8ir2cM"
                                            target="_blank">https://www.youtube.com/watch?v=W0VUl8ir2cM</a><br/>
                                        <br/>
                                        Adidum perumal Thaandavam adidum - Namavali<br/>
                                        <a href="https://www.youtube.com/watch?v=q3HcS4H6o9s"
                                            target="_blank">https://www.youtube.com/watch?v=q3HcS4H6o9s</a><br/>
                                        <br/>
                                        Srikantamiva pasvantham - Sridhara venkatesam<br/>
                                        <a href="https://www.youtube.com/watch?v=L1NTA0EZfFQ"
                                            target="_blank">https://www.youtube.com/watch?v=L1NTA0EZfFQ</a><br/>
                                        <br/>
                                        Srimad bodhendra desikendra namosthuthey - Bodhendra guru bajeham <br/>
                                        <a href="https://www.youtube.com/watch?v=c3aM8FQpbZw"
                                            target="_blank">https://www.youtube.com/watch?v=c3aM8FQpbZw</a><br/>
                                        <br/>
                                        Varumai agatri<br/>
                                        <a href="https://www.youtube.com/watch?v=t2J6Lb9pA-M"
                                            target="_blank">https://www.youtube.com/watch?v=t2J6Lb9pA-M</a><br/>
                                        <br/>
                                        Bavamana<br/>
                                        <a href="https://www.youtube.com/watch?v=n6dYmNJQLsY"
                                            target="_blank">https://www.youtube.com/watch?v=n6dYmNJQLsY</a><br/>
                                        <br/>
                                        NAMASANKEERTHANA MELA-2009 by Kunnakkudy School: </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://www.youtube.com/watch?v=xRxTg5Ixcao">https://www.youtube.com/watch?v=xRxTg5Ixcao</a>
                                    </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;</p>
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        <div>
                                            <div><strong>Audio:</strong><a href="https://www.youtube.com/watch?v=xRxTg5Ixcao"
                                                    target="_blank"><strong> </strong></a></div>
                                        </div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ZjU0MGFlZDZkZWUyYjdhOTAyODI5ZThmZmEzMmNiNTM6Ojo6Q2hpbnRheWFyZVNhdGhndXJ1bS5tcDM="
                                                target="_blank">Chintayare Sathgurum</a></div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MjUwYmRhZGIwZmFlNTVjM2ExNjA4ZWNkYWE2OTJlYmQ6Ojo6QW5qZWxBbmplbGluaUtvbmphbXVtYW5qZWwubXAz"
                                                target="_blank">Anjel anjel ini Konjamum anjel</a></div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MmNkOTlkN2Q4MzNkMjdiYzFmMjYxNzJmNDE4NzU4NGM6Ojo6TXVraGFsYWF2YW55YWFjaGkubXAz"
                                                target="_blank">Mukhalaavanyaachi Kaani</a></div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NzNhMDUwNjZmNGU1YjM1ZTI5YjdlMTcyNGIzM2Q2Yzk6Ojo6U3JpUmFtYUNoYW5kcmFLcnVwYWx1QmhhamFtYW5hLm1wMw=="
                                                target="_blank">Sri Rama Chandra Krupalu Bhaja mana</a></div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MGVkZDNhNGUzYzI3ODg0N2I2NDA4MzhkYTEyZTM1Nzk6Ojo6VGhvb25pbHVtaXJ1cHBhYW5UaHVydW1iaWx1bUlydXBwYWFuLm1wMw=="
                                                target="_blank">Thoonilum Iruppaan Thurumbilum Iruppaan</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NWU0OWNiZjQ0ZWE0N2JkYmM5NmU5YmJiNDA2ZTI2NTg6Ojo6WWVudGhhQmFsYXZhbnRoYW5vLm1wMw=="
                                                target="_blank">Yenthaa balavanthano</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MWE2NGFlMzcxZjIzMDk4M2NiNzdlMzJiMjI0ZjI5ZDM6Ojo6MDBQdW5kYWxlZWdhbS1KYWlKYWlSYW1LcmlzaG5hSGFyaS5tcDM="
                                                target="_blank">Pundaleegam - JaiJai RamKrishna Hari</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6M2VjNmUwYTFjNjZkZWZlOTlmZTAzODZhY2NjZDg1NjM6Ojo6MDFHdXJ1a3J1cGFBbmphbmFiYXlvbWVyaS1NYW5hbWVQcmVtYUJhcm8ubXAz"
                                                target="_blank">Guru krupa Anjana bayomeri - Maname PremaBaro</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ODc3OGYxN2IzMTMwZWYzODg2NGJlYTY5MzM3NjU2OWM6Ojo6MDJBYXJhdGhhbWFuZGFsdS1QYXJhbWFTYW5hYXRoYW5hLm1wMw=="
                                                target="_blank">Aaratha mandalu - ParamaSanaathana</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NWUxNzM0NTcxNDJiNmI1ZTNhNWQxMDRmNGE2MzA3ODY6Ojo6MDNTYWFudGhhTWFoYW50aG8ubXAz"
                                                target="_blank">SaanthaMahantho</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YWUzODNlZGEzZjgwZWZmY2JjMzdkMzQ3ZjE3YjVkYzA6Ojo6MDRZZWthYXJhbmNoYWxla2FhbmNoYWxlLU5hbWRldlRodWthcmFtR3lhbm8ubXAz"
                                                target="_blank">Yekaaranchale kaanchale - Namdev Thukaram GyanoBai Thukaram</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NTIyMWRmOGQxYjQxNGI3YjBiMGM3ODA0MDhhMDY2ZjM6Ojo6MDVWYW5kaGVNb2hhbmEtTW9yaWxhZ2VlbGFnYW5hLm1wMw=="
                                                target="_blank">Vandhe Mohana - Morilagee lagana</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NjM3YjhjMzNjNmU1ZmNiZGMwODk4ZmE2YWRkZWQ3Nzk6Ojo6MDZTcmVlSGFyZU5pamFuZU5pdGh5YW0tTmFhbWF2YWxpLm1wMw=="
                                                target="_blank">SreeHareNijaneNithyam</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ZDBmMTQ3NjZiOTA5MTVkNGY3NDVlMTJkZTA5ZWM1MDA6Ojo6MDhHb3ZpbmRoYW1hYWRoYXZhLm1wMw=="
                                                target="_blank">Govindha maadhava</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6OTRiNWE4NDhjMGJjMTA3NmZlNTg0NzllZmEzMDBlN2U6Ojo6MDlBbmJhcnVra3VBbmJhcmV5TmVlKE5hbWFhdmFsaSkubXAz"
                                                target="_blank">Anbarukku Anbarey Nee (Namaavali)</a><br/>
                                            <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6OTA4ZDM1MDc1NTI0NWRiOTFlY2VkN2MyZGVlYzU2NmY6Ojo6MTBWYW5kYWFyS3V6aGFsaS1TYXJhdGhleXNhcmF0aGV5KE5hbWFhdmFsaSkubXAz"
                                                target="_blank">VandaarKuzhali - Sarathey sarathey (Namaavali)</a><br/>
                                            Smarana maathrena nama bhakti - Sri guru Bodhendram manasa<br/>
                                            Srikantamiva pasvantham - Gurumurthy padamule<br/>
                                            Yasyaangam kanagaba - Chintayare sathgurum&nbsp;<br/>
                                        </div>
                                        <div> </div>
                                        <div> </div>
                                        <div>
                                            <font face="'Times New Roman', Times, serif" size="5"><u><br/>
                                                    Bhajan @ Sri CTO Santhanam House (2007)<br/>
                                                    <br/>
                                                </u></font>
                                        </div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6N2M4MDMyOGZhMWRkMzJiNmJhMzdiZWIyMzdhNWVmYmY6Ojo6MDFEaHlhbmFzbG9rYW1UaG9kYXlhbWFuZ2FsYW0ubXAz"
                                                target="_blank">01 Dhyana slokam Thodaya mangalam</a><br/>
                                            <br/>
                                        </div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NWUzNjM1MTI4MmUwNGJhN2FjNjlkZDMyOTMwZTFlMmE6Ojo6MDJCcmFobWFuYW5kYW1wYXJhbWEtTmVlcGFhdGhhLm1wMw=="
                                                target="_blank">02 Brahmanandam parama - Nee paatha</a><br/>
                                            <br/>
                                        </div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ODgzMDU2MWQwZTE1MzQ5NThmMDBmMzMxYWM3MmIxYzM6Ojo6MDNBemh1bnRob3J1bWFuYWlra3VtYW5uYWktRW5uYXB1bm5peWFtLm1wMw=="
                                                target="_blank">03 Azhunthorum anaikkum annai - Enna punniyam</a><br/>
                                            <br/>
                                        </div>
                                        <div> </div>
                                        <div>
                                            <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MDEwNDUzOGQ3Mzg3NjY1ZmI0ZTI2NTlmZjQ2Nzk4ZDg6Ojo6MDRTYW50aGFtYWhhbnRoby1oYXJpYmhha3RoYXNhbnRoYS1nbmFuZXNody5tcDM="
                                                    target="_blank">04 Santhamahantho - hari bhaktha santha - gnaneshwara mauli</a><br/>
                                                <br/>
                                            </div>
                                            <div> </div>
                                            <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MGIyMGU1MTVmZmU1YWU1MTQzZjc0NjcyODFkOGU1ZGY6Ojo6MDVhc2h0YXBhZGhpc2xva2FtQW5la2FuYWFyaXBhcmlyYW1iYS1jaGFuZC5tcDM="
                                                    target="_blank">05 ashtapadhi slokam Aneka naari pariramba - chandana
                                                    sachitha</a><br/>
                                                <br/>
                                            </div>
                                        </div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6MmJkNWVkOWJiZWYyODJkYTkyZjcwN2ViZWQ0MWVmMWM6Ojo6MDZzYWFzYXNhdGh2YXNhc2FuYW5kYW0ta2FseWFuYWFzaHRhcGFkaGkubXAz"
                                                target="_blank">06 saasasathvasa sanandam - kalyana ashtapadhi</a><br/>
                                            <br/>
                                        </div>
                                        <div> </div>
                                        <div><a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NzA5YTMzYzZiZmU4N2I3OGFmNjQ5Nzg4ZWFhNzU2NzI6Ojo6MDdrYWx5YW5ha3Jpc2huYShuYW1hdmFsaSkubXAz"
                                                target="_blank">07 kalyana krishna (namavali)</a></div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <div
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        <a href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmEzM2I1ODA4OTA4MDFiYTI2OWNkNmYzZmQ0MThlNzg6Ojo6MDhLcmlzaG5ha3Jpc2huYWdvdmluZGEoTmFtYXZhbGkpLm1wMw=="
                                            target="_blank">08 Krishna krishna govinda (Namavali)</a></div>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6NjY4NmViOWUyYTI4ODA4MTEwOTMxZmQwNDlhNDU0NWY6Ojo6MDl5YXRoYW5ncmlwYW5nZS1rYWx5YWFuYWdvcGFsYW0ubXAz"
                                            target="_blank">09 yathangri pange - kalyaana gopalam</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YWE1NDJlMzJkNzY4MWM2ZGQ0MzRlOGRiNjJkMjk1OTQ6Ojo6MTBCYXJvbXVyYXJleS5tcDM="
                                            target="_blank">10 Baro murarey</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6M2ZiYjNhMGQ0MGU0MjZkZTlkNGMxYTg5MmE1MTRhNzA6Ojo6MTFLYXN0aHVyaWdhbmEubXAz"
                                            target="_blank">11 Kasthurigana</a> </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6OTY3NzkxNjFjZDZhY2EzNmM2MDk4ZjFlMTIwMTkwYTI6Ojo6MTJEYWFzYXNpc2FtcGF0aGkubXAz"
                                            target="_blank">12 Daasasi sampathi</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        <a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmViMjBmNTI5MGZkNDBlMjRmZTgyYWFmMGFhYmQyZmI6Ojo6MTNSYXRobmFqYWRpdGhhc2ltaGFzYW5hLm1wMw=="></a><a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmViMjBmNTI5MGZkNDBlMjRmZTgyYWFmMGFhYmQyZmI6Ojo6MTNSYXRobmFqYWRpdGhhc2ltaGFzYW5hLm1wMw=="
                                            target="_blank"></a><a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmViMjBmNTI5MGZkNDBlMjRmZTgyYWFmMGFhYmQyZmI6Ojo6MTNSYXRobmFqYWRpdGhhc2ltaGFzYW5hLm1wMw==">&nbsp;</a><a
                                            href="http://rangavittal.com/Documents/13 Rathnajaditha simhasana.mp3" target="_blank">13
                                            Rathnajaditha simhasana</a><a
                                            href="http://rangavittal.com/Documents/13 Rathnajaditha simhasana.mp3"
                                            target="_blank"></a><a
                                            href="http://rangavittal.com/Documents/13 Rathnajaditha simhasana.mp3" target="_blank"></a>
                                    </p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6ZjE4YjQ3YTYzN2JiNGYyYmVjNTRkMzJmY2FhYWRmYmU6Ojo6MTREaGFueWFhbmphbmljaGEubXAz"
                                            target="_blank">14 Dhanya anjanicha</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                        &nbsp;<a
                                            href="https://cache.nebula.phx3.secureserver.net/obj/OUMwN0ZGMkZEMTIwMDBFM0FGRDI6YmJmNDhiMWJmYTJhMzRhMDIwNTI0MjIyNTEwZWE4ZjU6Ojo6MTVCYXZhbWFhbmEubXAz"
                                            target="_blank">15 Bavamaana</a></p>
                                    <p
                                        styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                              </p>
                              <p
                                  styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                              </p>
                              <p
                                  styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                              </p>
                              <p
                                  styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                              </p>
                              <p
                                  styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                              </p>
                              <p
                                  styleName="color: #724634; font-family: georgia, 'nimbus roman no9 l', serif; font-size: 13px; font-style: normal; font-weight: normal; line-height: 22px;">
                                  <font size="1">These are free mp3/audio downloads and does not come under any external
                                      copyrights, patents or license. <br/>
                                      <br/>
                                      * The users may be advised that the downloads provided may be recorded live and the
                                      recording quality may not be upto the recording standards. <br/>
                                      <br/>
                                      To view all downloads from google share, please<a
                                          href="https://docs.google.com/folder/d/0B0S2ZlXDN-CtX2FLSnZ0WUhpZjQ/edit"
                                          target="_blank"> click here</a> !!!</font>
                              </p>
                          </font>
                      </b>
                  </div>
              </div>
          </div>
        
                  </AccordionItemPanel>
              </AccordionItem>
          </Accordion>    
         
        </Grid> 
        
      </div>
    );
  }
}

export default Posts;