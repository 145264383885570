import React, { Component } from "react";
import "../js/aws-exports.js";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';


class Photos extends Component {

    constructor(props){
        super(props)
        this.state = {
            appConstants: window.appConstants
        }
        window.listAlbums();
        window.clickLog("load", "Photos");
    }  

    openUrl(value){
        window.open(value, false);
      }
     
      
  render() {
    return (
      <ThemeProvider theme={theme}>
            <div>
                <div style={{ marginTop: 40, padding: 30 }}>
                    <Grid container spacing={40} justify="center">
                        <TypoGraphy variant="h6">
                            RadheKrishna Welcome to RadhaKrishna Sankeerthana Samajam!!!
                        </TypoGraphy>
                    </Grid>
                    
                    <Grid container spacing={40} justify="left">
                        <TypoGraphy>
                          <div id="app"></div>
                        </TypoGraphy>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    );
  }
}
 
export default Photos;