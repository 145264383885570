import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import TypoGraphy from '@material-ui/core/Typography'

//import NavBar from './components/navbar'
  
class App extends Component {

  constructor(props){
      super(props)
      this.state = {
          appConstants: window.appConstants
      }
      }  
      NavUrl(value){
          window.location.href = window.location.href + value;
      }
      
    render() {
      
          return (
              <div className="pad-left-page">
                  
                   <AppBar color="primary" position="static">
                    <Toolbar>
                      <TypoGraphy variant="title"
                        color="inherit"
                      >
                        RadheKrishna Welcome to RadhaKrishna Sankeerthana Samajam!!!
                      </TypoGraphy>
                    </Toolbar>
                  </AppBar>
              </div>
          )
      
    }
  }
   
  export default App;

// class App extends Component {
//   render() {
//     return (
//       <div>
//         <AppBar color="primary" position="static">
//           <Toolbar>
//             <TypoGraphy variant="title"
//               color="inherit"
//             >
//            </TypoGraphy>
//            <NavBar />
//           </Toolbar>
//         </AppBar>

//       </div>
//     );
//   }
// }
// export default App;