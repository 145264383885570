//loader.js
window.$ = window.jQuery = require('jquery')

window.S4 = function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
}
 
window.getGuid = function(){
    return (window.S4() + window.S4() + "-" + window.S4() + "-4" + window.S4().substr(0,3) + "-" + window.S4() + "-" + window.S4() + window.S4() + window.S4()).toLowerCase();
}
window.guid = (window.S4() + window.S4() + "-" + window.S4() + "-4" + window.S4().substr(0,3) + "-" + window.S4() + "-" + window.S4() + window.S4() + window.S4()).toLowerCase();

window.clickLog = function(action, component){
    var callerid=window.appConstants.AppName; 
    var url=window.appConstants.clicklogUrl; 
    var contextid = window.appConstants.Allow + "-" + window.guid;
    var requestid = window.getGuid();
    var type = "webpage"
    var hval = "ALLOW=" + window.appConstants.Allow;
      
    var request= {
        "requestid": requestid,
        "contextid": contextid,
        "callerid": callerid,
        "component": component,
        "action": action,
        "type": type
    }
    
    if(window.appConstants.enableClickLogs){
        window.clickLogPost(url, hval, request);
    }
    
  }

window.clickLogPost = function(url, hval, request){
    window.$.ajax({
        type: "POST",
        //url: "https://wbk8ptlj12.execute-api.us-east-1.amazonaws.com/dev/clicklogger",
        url: url,
        crossDomain: true,
        data: JSON.stringify(request),
        contentType: "application/json",
        header: {
            'Authorization': hval
        },
        dataType: "json",
        success: function(data, status){
           //console.log("Data: " + data.statusCode);
        }
   });
}

window.getList = function (){
    window.$.ajax({
      url: "https://ya5odksneh.execute-api.us-east-1.amazonaws.com/dev",
      type: 'GET',
      dataType: 'json', // added data type
      success: function(res) {
         window.makeLayoutTable(res);
      }
  });
}

window.makeLayoutTable = function (data){
      var table = '<layout-table>';
      var tblHeader = "<layout-header><layout-column>Date</layout-column><layout-column>Program</layout-column><layout-column>location</layout-column><layout-column>Additional Details</layout-column></layout-header>";
      var tblEachRow = "";
  
      window.$.each(data, function(i, item) {
          tblEachRow += window.returnLayoutRow(item);
      });
      
      var tblEnd = "</layout-table>";
      var htmlContent = table+tblHeader+tblEachRow+tblEnd;
      window.$("#htmlcontent").html(htmlContent);
      //$( "#accordion" ).accordion();
      console.log(htmlContent);
  }

window.returnLayoutRow = function (item){
    var tblRow = "";
    if(!item.completed){
      tblRow = "<layout-row><layout-column>" + item.datetime + "</layout-column><layout-column>" + item.title + "</layout-column><layout-column>" + item.provider + "</layout-column><layout-column>" + item.comments + "</layout-column></layout-row>";
    }
    return tblRow;
}
