import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styleName=s, see 'styleName=s' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class ProgramAndEvents extends Component {

    constructor(props){
        super(props)
        this.state = {
            appConstants: window.appConstants,
            downloads: window.returnLayoutRow
        }
        window.getList()
        window.clickLog("load", "ProgramAndEvents");
    }  

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <ThemeProvider theme={theme}>
            <div>
                <div style={{ marginTop: 20, padding: 30 }}>
                    <Grid container spacing={40} justify="center">
                        <TypoGraphy variant="h6">
                          Program & Events
                        </TypoGraphy>
                    </Grid>
                    
                    <Grid container item xs={12} spacing={1} style={{ marginTop: 20}}>
                        <TypoGraphy>
                        Recent Youtube Videos: &nbsp;
                      </TypoGraphy>
                      <TypoGraphy>
                            <ListItem><a href="https://youtu.be/EByS7Cjre5o" target="_blank">Nama 2020 - Oothukadu Saptha Gurukeerthanais</a></ListItem>
                            <ListItem><a href="https://www.youtube.com/watch?v=ol8w9XIB5IQ" target="_blank">Papanasam Sri Sivan Keerthanams Yuva Bhajan Mela Trust, Palakkad</a></ListItem>
                        </TypoGraphy>
                        <TypoGraphy>
                          <div id="htmlcontent"></div> <br/>
                        </TypoGraphy>
                    </Grid>
                    <Grid>
                      <Accordion>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      Past Events
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                            
                              <AccordionItemPanel>

                              <a href="https://drive.google.com/folderview?id=0B0S2ZlXDN-CtUDItdlV0cUZ1RkU&amp;usp=sharing" target="_blank">Nov 7th - Sri Hari Rama subbu's residence @ Frisco Texas</a> <br/>
                              <a href="https://www.youtube.com/playlist?list=PL1sFFfpPjmz_FjfYtu0VdJeEfR4ZQtZpj&amp;feature=em-share_playlist_user"  target="_blank">Radha kalyanam @ Kansas - Aug 2014</a><br/>
                              <a href="https://www.youtube.com/watch?v=DNFtPxyE-zg&amp;list=PL1sFFfpPjmz8lBjrr-h8wFSd3BIUTCNYT" target="_blank"> Sampradaya Bhajans - Meenakshi Kalyanam</a><br/>
                              <a href="https://drive.google.com/folderview?id=0B0S2ZlXDN-CtaGNhZFZ5MkdlSjg&amp;usp=sharing"  target="_blank">Namasankeerthanam @ Bond St, Irving TX NOV 2014</a><br/><br/>


                              Individual Links Below:<br /><br/>                                  
                                      <a href="https://www.youtube.com/watch?v=UsHwBSz5fjQ" target="_blank">Thodaya mangalam, guru keerthanai, Ashtapadhi 1st session</a><br/>
                                      <a href="https://www.youtube.com/watch?v=K6H471XZ71s" target="_blank">Ashtapadhi - Hey nandana, vanamali vasudeva jagan mohana</a><br/>
                                      <a href="https://www.youtube.com/watch?v=l2y80Hp7aoo" styleName="line-height: 21.29px; font-weight: inherit; cursor: pointer;" target="_blank">Ashtapadhi 3rd session&nbsp;</a><br/>
                                      <a href="https://www.youtube.com/watch?v=sYlVM-xrOnE" target="_blank">Ashtapadhi 3rd session - Krishna murali gopala</a><br/>
                                      <a href="https://www.youtube.com/watch?v=WQc43E9I0fA" target="_blank">Ashtapadhi 3rd session - &nbsp;Oh rama nee namam, Jaya janaki , Atma rama ananda ramana</a><br/>
                                      <a href="https://www.youtube.com/watch?v=c9wUdeET32U" target="_blank">Ashtapadhi 3rd session - Chethashri ramam, Sakala loka nayike, Charanaagatha vathsala, Upacharamu</a><br/>
                                      <a href="http://youtu.be/De6GQumTjXQ" target="_blank">Divyanamam 1st session</a><br/>
                                      <a href="http://youtu.be/77kl1o29Y8Q" target="_blank">Divyanamam 2nd session - kamala vallabha, krishna krishna, aloka tulasi</a><br/>
                                      <a href="https://www.youtube.com/watch?v=ds5HPAB3a6w" styleName="line-height: 21.29px; font-weight: inherit; cursor: pointer;" target="_blank">Murahara , yelello, namacha bazaar</a><br/>
                                      <a href="https://www.youtube.com/watch?v=5xHYyRyGenA" target="_blank">saasasathvaja sanandam, radhey radhey radhey govinda, radha kalyana vaibogame</a><br/>
                                      <a href="http://youtu.be/Incrsyz23So" target="_blank">Allo nere , Suvvi, &nbsp;chitha juni jayamangalam, Viharathi haririha - jathi</a> <br/><br/>
                                      

                              MEENAKSHI KALYANAM Feb 2014 <br/><br/>
                                          <a href="https://www.youtube.com/watch?v=DNFtPxyE-zg"target="_blank">01 Slokam - Thodaya Mangalam (Sivan)</a><br/>
                                          <a href="https://www.youtube.com/watch?v=KLDSKxo1MOo"target="_blank">02 Guru Keerthanai</a><br/>
                                          <a href="https://www.youtube.com/watch?v=alcJHO6MJCU"target="_blank">03 Sivashtapadhi - Kalayanam</a><br/>
                                          <a href="https://www.youtube.com/watch?v=sFj9HKo9pyE"target="_blank">04 Sivashtapadhi - Kalayanam</a><br/>
                                          <a href="https://www.youtube.com/watch?v=-ba6Tt7F9v0"target="_blank">05 Sivashtapadhi - Kalayanam</a><br/>
                                          <a href="https://www.youtube.com/watch?v=nN8OhWQD8O4"target="_blank">06 Sivashtapadhi - Kalayanam</a><br/>
                                          <a href="https://www.youtube.com/watch?v=_v3EAWj6SFY"target="_blank">07 Sivashtapadhi - Kalayanam</a><br/>
                                          <a href="https://www.youtube.com/watch?v=_ogLEHp0q-g"target="_blank">08 Sivashtapadhi - Kalayanam</a><br/><br/>
                                  
                                  
                                 <strong styleName="font-family: georgia, &quot;nimbus roman no9 l&quot;, serif;">Charitable Activities</strong><br />
                                <br />
                                
                                Our trust is mainly a Non profitable charitable one. with the available
                                sources we have been donating &nbsp;during Sangeetha Mahothsavam inaugural day
                                i.e 21st Nov. Artificial Limb for 6 persons and Ed;.Assistance of Rs.5000
                                for 2 School &nbsp;children, Medical Assitance for Rs.max.3 to Rs.5000. We have
                                in this year beginning during January given Rs.5000 for two persons for their
                                sister/daughter"s Marriage.&nbsp;<br />
                                <br />
                                <span styleName="font-weight: normal;">Last year we have conducted a general
                                Medical Camp at Baba Gurukulam Polichalur an orphange where 120 children
                                and 25 old persons general.checkup and proving Medicines etc.&nbsp;</span><br />

                                <br />
                                <span styleName="font-weight: normal;">Every year during Karthikai Pounami poor feeding at Baba Gurukulam inmates appl Rs.5000. from the trust fund.</span><br />
                                <br />
                                We conduct an Eye camps and also assistance for the Leprosy patients subject to availability of funds.&nbsp;<br />                                                  
                                  
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>  
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    );
  }
}
 
export default ProgramAndEvents;