import React, { Component } from "react";
import {
    //Route,
    NavLink,
    HashRouter
  } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import superagent from "superagent";
import Home from "./Home";
// Namasankeerthanam, ProgramAndEvents, Downloads, ContactUs, Photos
import Namasankeerthanam from "./Namasankeerthanam";
import ProgramAndEvents from "./ProgramAndEvents";
import Downloads from "./Downloads";
import ContactUs from "./ContactUs";
import Photos from "./Photos";
import Posts from "./Posts";

import "../js/loader.js";
import "../js/appConstants.js";
import "../js/youtube-videos.js";
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
export default class Main extends Component  {

  constructor(props){
    super(props)
    this.state = {
        appConstants: window.appConstants,
        appPrefix: window.appConstants.Allow,
        contextid: window.appConstants.Allow + "-" + window.guid
    }
    //console.log("call clicklog")
    window.clickLog("load", "homepage")    
  }  

  openUrl(value){
    //window.clickLog("load", "blog")  
    
    window.open(value, false);
  }

  componentDidMount(){
      
  }

  

  render() {
    
    return (
        <HashRouter>
            <div>
                <div>
                    <Menu >                       
                        <li><NavLink  className="menu-item--small" exact to="/">Home &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></li>
                        <li><NavLink  className="menu-item--small" to="/Namasankeerthanam">Nama</NavLink></li>
                        <li><NavLink  className="menu-item--small" to="/ProgramAndEvents">Programs</NavLink></li>
                        <li><NavLink  className="menu-item--small" to="/Downloads">Music</NavLink></li>
                        <li><NavLink  className="menu-item--small" to="/ContactUs">Contact&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></li>
                        <li><NavLink  className="menu-item--small" to="/Photos">Photos</NavLink></li>
                        {/* <li><NavLink  className="menu-item--small" to="/Posts">Posts</NavLink></li> */}
                    </Menu>
                </div>
                <div className="header">
                    <ul className="pad-left-60">
                        <li><NavLink exact to="/">Home</NavLink></li>
                        <li><NavLink to="/Namasankeerthanam">Namasankeerthanam</NavLink></li>
                        <li><NavLink to="/ProgramAndEvents">Program and Events</NavLink></li>
                        <li><NavLink to="/Downloads">Music</NavLink></li>
                        <li><NavLink to="/ContactUs">Contact Us</NavLink></li>
                        <li><NavLink to="/Photos">Photos</NavLink></li> 
                        {/* <li><NavLink to="/Posts">Posts</NavLink></li>  */}
                    </ul>
                    
                </div>
               
                <div className="content ">
                    
                    <Route exact path="/" component={Home}/>
                    <Route path="/Namasankeerthanam" component={Namasankeerthanam}/>
                    <Route path="/ProgramAndEvents" component={ProgramAndEvents}/>
                    <Route path="/Downloads" component={Downloads}/>
                    <Route path="/ContactUs" component={ContactUs}/>
                    <Route path="/Photos" component={Photos}/>
                    {/* <Route path="/Posts" component={Posts}/> */}
                </div>
            </div>
      </HashRouter>
    );

    
  }
}
 