import React, { Component } from "react";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';

class ContactUs extends Component {

  constructor(props){
    super(props)
    this.state = {
        appConstants: window.appConstants,
        downloads: window.returnLayoutRow
    }
    window.getList()
    window.clickLog("load", "ContactUs");
}  

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <ThemeProvider theme={theme}>
            <div>
                <div style={{ marginTop: 20, padding: 30 }}>
                    <Grid container spacing={40} justify="center">
                        <TypoGraphy variant="h6">
                            Contact Us
                        </TypoGraphy>
                    </Grid>
                    
                    <Grid>
                        <TypoGraphy>
                          Papanasam Ramani Bhagavathar
                        </TypoGraphy>
                        <TypoGraphy>
                          34-35, A-Block, G7 Perumal Koil St, West Mambalam, Chennai, India
                        </TypoGraphy>
                        <TypoGraphy>
                          <div style={{paddingTop: "0.5px"}}>&nbsp;</div>
                        </TypoGraphy>
                        <TypoGraphy>
                          <TypoGraphy color="secondary">Email: </TypoGraphy>
                            <div>
                              <ul>
                                <li><a href="mailto:ramanipr@hotmail.com"> ramanipr@hotmail.com</a></li>
                                <li><a href="mailto:rksamajam@gmail.com"> rksamajam@gmail.com</a> </li>
                              </ul>
                            </div>
                        </TypoGraphy>
                        <TypoGraphy>
                           <TypoGraphy color="secondary">Phone: </TypoGraphy>  91 044 24898527 
                        </TypoGraphy>
                        <TypoGraphy>
                            Sankara Narayanan: 
                            <a href="mailto:rsn.mridangam@gmail.com"> rsn.mridangam@gmail.com</a>
                        </TypoGraphy>
                        <TypoGraphy >
                            Shiva Ramani: 
                            <a href="mailto:shiva.ramani@live.com"> shiva.ramani@live.com</a>
                        </TypoGraphy>                        
                        <TypoGraphy>
                            <TypoGraphy color="secondary">Blog: </TypoGraphy>
                              <a href="http://fb.me/prrbaghavathar" target="_blank">@facebook</a>
                              <a href="http://rsnmridangam.com/" target="_blank">@Sankaranarayanan website</a>
                        </TypoGraphy>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
      
    );
  }
}
 
export default ContactUs;