export default {
	apiGateway: {
		REGION: 'us-east-1',
		URL: ' https://35c2d7c5ob.execute-api.us-east-1.amazonaws.com/dev/' 
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_3YLvKABxb', 
		APP_CLIENT_ID: '48ep5ahlc8d6n2kb1t8m549v6a', 
		IDENTITY_POOL_ID: 'us-east-1:9e186ce9-85b4-42b5-8470-dabcf80afb40' 
	}
};
