import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
//import './index.css';
import App from './App';
import config from './config';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from './theme';

import "./css/menu.css";
import "./css/programs.css";

import Main from "./js/Main";

Amplify.configure({
	Auth: {
		mandatorySignIn: false,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	API: {
		endpoints: [
			{
				name: 'search_rksamajam_music_by_external_call', //'searchRkSamajamMusicByExternalCall',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'recent_uploads_rksamajam_music_call', //'recentUploadsRkSamajamMusicCall',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'ingest_user_action_rksamajam_music_call',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'search_audio_rksamajam_music_by_external_call', 
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'recent_audio_uploads_rksamajam_music_call',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'get_audio_albums_call',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			}
		]
	}
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider  theme={theme}>
      {/* <App /> */}
      <React.Fragment>
        <CssBaseline />
        <Main/>
      </React.Fragment>
    </MuiThemeProvider >
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
