import React, { Component } from "react";
import { API } from 'aws-amplify';
import { Grid, Paper, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { TextField } from '@material-ui/core';
import ReactPlayer from 'react-player'
import Box from '@material-ui/core/Box';
import TypoGraphy from '@material-ui/core/Typography'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import styled from 'styled-components';
import Carousel from 'react-elastic-carousel';
import "../css/rksplayer.css";

function getTime(time) {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      );
    }
  }

//function Posts(props) {
class Downloads extends Component {  

  constructor(props){
      super(props)
      this.state = {
          isLoading: true,
          appConstants: window.appConstants,
          rkSamajamMusicByCall: [], 
          recentUploadsRkSamajamMusicCall: [],
          rkSamajamAudioMusicByCall: [],
          rkSamajamAudioAlbumsByCall: [],
          albumdetails:[],
          playerList: [],
          playlistTitle: "",
          checked: true,
          searchCriteria: "vaikunta",
          currentSong: null,
          currentLink: null,
          currentId: null,
          music: "stopped",
          currentTime: null,
          duration: null
      }
      this.handleSearchChange = this.handleSearchChange.bind(this);
      this.handleCheck = this.handleCheck.bind(this);
      this.searchRkSamajamMusicByCall = this.searchRkSamajamMusicByCall.bind(this);
      this.getRecentUploadsRkSamajamMusicCall = this.getRecentUploadsRkSamajamMusicCall.bind(this);
      this.getRkSamajamAudioAlbumsByCall = this.getRkSamajamAudioAlbumsByCall.bind(this);
      this.getRkSamajamAudioAlbumsByCall();
      this.searchRkSamajamMusicByCall(true);
      this.getRecentUploadsRkSamajamMusicCall();
      this.handleUserActionclick = this.handleUserActionclick.bind(this);
      this.ingestUserActionRkSamajam = this.ingestUserActionRkSamajam.bind(this);
      this.setAlbumToPlayList = this.setAlbumToPlayList.bind(this);

      window.clickLog("load", "Downloads");  
  }  
  
    setAlbumToPlayList(album){
        var playerList = [];
        var currentSong = ""
        var currentId = "";
        var currentLink = "";
        var cnt = 0;

        this.state.albumdetails.forEach(a => {
            if(a.album == album){
                playerList.push(a);
                if(cnt == 0){
                    currentSong = a.title;
                    currentId = a.id;
                    currentLink = a.link;
                }
                cnt = 1;
            }
        });
        if(playerList.length > 0){

            this.setState({ playerList });
            var rkSamajamAudioMusicByCall = [];
            this.setState({ rkSamajamAudioMusicByCall });

            this.setState({ currentSong: currentSong, currentLink: currentLink, currentId: currentId})
            var playlistTitle = "Playlist (Album): " + album;
            this.setState({ playlistTitle });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentSong !== prevState.currentSong) {
        let track = this.state.currentLink;

        if (track) {
            this.music.src = track;
            this.music.play();
            this.setState({
                music: "playing"
            });
            this.handleUserActionclick(this.state.currentId, "PLAYED", "AUDIO")
        }
        }

        if (this.state.music !== prevState.music) {
        if (this.state.music === "paused") {
            this.music.pause();
            this.handleUserActionclick(this.state.currentId, "PAUSED", "AUDIO")
        }
        if (this.state.music === "playing" && prevState.music === "paused") {
            this.music.play();
            this.handleUserActionclick(this.state.currentId, "PLAYED", "AUDIO")
        }
        if (this.state.music === "stop") {
            this.music.pause();
            this.currentTime = 0;
            this.setState({
                currentSong: null,
                currentLink: null,
                currentId: null
            });
            this.handleUserActionclick(this.state.currentId, "STOPPED", "AUDIO")
        }
        }
    }
    
    async componentDidMount() {
        this.music.addEventListener("timeupdate", e => {
            this.setState({
              currentTime: e.target.currentTime,
              duration: e.target.duration
            });
          });

        this.setState({ isLoading: false });
    }

    componentWillUnmount() {
        this.music.removeEventListener("timeupdate", () => {});
      }
  
  async searchRkSamajamMusicByCall(isChecked){
        this.setState({ isLoading: false });
        var searchCriteria = this.state.searchCriteria.toUpperCase()
        if(searchCriteria.length > 3){
            this.setState({ isLoading: false });
            let rkSamajamMusicByCall = await this.rkSamajamMusicByCall(isChecked);
            this.renderRKSamajamMusicByCall(rkSamajamMusicByCall) 
            this.setState({ rkSamajamMusicByCall });

            let rkSamajamAudioMusicByCall = await this.rkSamajamAudioMusicByCall(isChecked);
            this.renderRKSamajamAudioMusicByCall(rkSamajamAudioMusicByCall) 
            this.setState({ rkSamajamAudioMusicByCall });
        }
    }


    rkSamajamMusicByCall(isChecked) {
        var searchBy = this.state.searchCriteria;
        var includeTags = isChecked;// this.state.checked;
        return API.get('search_rksamajam_music_by_external_call', 'search_rksamajam_music_by_external_call', {'queryStringParameters': {'searchBy': searchBy, 'includeTags': includeTags}});
    }

    rkSamajamAudioMusicByCall(isChecked) {
        var searchBy = this.state.searchCriteria;
        var includeTags = isChecked;// this.state.checked;
        return API.get('search_audio_rksamajam_music_by_external_call', 'search_audio_rksamajam_music_by_external_call', {'queryStringParameters': {'searchBy': searchBy, 'includeTags': includeTags}});
    }

    getRKSamajamAudioAlbumsByCall() {
        return API.get('get_audio_albums_call', 'get_audio_albums_call', {'queryStringParameters': {}});
    }

    recentUploadsRkSamajamMusicCall(){
        return API.get('recent_uploads_rksamajam_music_call', 'recent_uploads_rksamajam_music_call', {});
    }

    ingestUserActionRkSamajam(id, user_action, component){
        return API.get('ingest_user_action_rksamajam_music_call', 'ingest_user_action_rksamajam_music_call', {'queryStringParameters': {'id': id, 'user_action': user_action, 'component': component}});
    }

    renderRKSamajamMusicByCall(rkSamajamMusicByCall) {
        console.log(rkSamajamMusicByCall);
        let jsonText = JSON.stringify(rkSamajamMusicByCall.message);
        return jsonText;
    }

    renderRKSamajamAudioMusicByCall(rkSamajamAudioMusicByCall) {
        console.log(rkSamajamAudioMusicByCall);
        let jsonText = JSON.stringify(rkSamajamAudioMusicByCall.message);
        return jsonText;
    }

    async getRkSamajamAudioAlbumsByCall(){
        let rkSamajamAudioAlbumsByCall = await this.getRKSamajamAudioAlbumsByCall();
        this.renderRKSamajamAudioAlbumsByCall(rkSamajamAudioAlbumsByCall) 
        this.setState({ rkSamajamAudioAlbumsByCall });
    }

    renderRKSamajamAudioAlbumsByCall(rkSamajamAudioAlbumsByCall){
        console.log(rkSamajamAudioAlbumsByCall);
        let jsonText = JSON.stringify(rkSamajamAudioAlbumsByCall.message);
        return jsonText;
        //return {};
    }

    async getRecentUploadsRkSamajamMusicCall(){
        this.setState({ isLoading: false });
        let recentUploadsRkSamajamMusicCall = await this.recentUploadsRkSamajamMusicCall();
        this.renderRecentUploadsRkSamajamMusicCall(recentUploadsRkSamajamMusicCall) 
        this.setState({ recentUploadsRkSamajamMusicCall });
    }
    renderRecentUploadsRkSamajamMusicCall(recentUploadsRkSamajamMusicCall){
        console.log(recentUploadsRkSamajamMusicCall);
        let jsonText = JSON.stringify(recentUploadsRkSamajamMusicCall.message);
        return jsonText;
    }


  handleCheck() {
    var isChecked = !this.state.checked;
    this.setState({checked: isChecked});
    if(this.state.searchCriteria.length > 3){
        this.searchRkSamajamMusicByCall(isChecked);
    }
    else{
        var rkSamajamMusicByCall = [];
        this.setState({ rkSamajamMusicByCall });
    }
  }

  async handleUserActionclick(id, user_action, component){
      if(id != "" && user_action != ""){
        await this.ingestUserActionRkSamajam(id, user_action, component);
      }
  }

  handleSearchChange(e) {
    var searchCriteriaVal = e.target.value;
    var isChecked = this.state.checked;
    console.log(searchCriteriaVal);
    this.setState({
        searchCriteria: searchCriteriaVal
    })
    if(searchCriteriaVal.length > 3){
        this.searchRkSamajamMusicByCall(isChecked);
    } else{
        var rkSamajamMusicByCall = [];
        this.setState({ rkSamajamMusicByCall });
    }
    
  }

  render() { 

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 3 }
      ];

    const HoverText = styled.p`
        color: #000;
        :hover {
            color: #ed1212;
            cursor: pointer;
        }
    `
    var videolinks = [];
    var recentUploadVideos =[];
    var albums = [];

    const { items } = this.state;
    
    if(!this.state.isLoading && this.state.rkSamajamMusicByCall &&
        this.state.rkSamajamMusicByCall.message){
        let details = this.state.rkSamajamMusicByCall;
        

        if(details && details.message && details.message.statusDetails && 
            details.message.statusDetails.serviceResponse && 
            details.message.statusDetails.serviceResponse.Items &&
            details.message.statusDetails.serviceResponse.Items.length > 0){

                videolinks = details.message.statusDetails.serviceResponse.Items;
            }
    }
    if(!this.state.isLoading && this.state.recentUploadsRkSamajamMusicCall &&
        this.state.recentUploadsRkSamajamMusicCall.message){
        let details = this.state.recentUploadsRkSamajamMusicCall;
        

        if(details && details.message && details.message.statusDetails && 
            details.message.statusDetails.serviceResponse &&
            details.message.statusDetails.serviceResponse.length > 0){

                recentUploadVideos = details.message.statusDetails.serviceResponse;
            }
    }

    if(!this.state.isLoading && this.state.rkSamajamAudioAlbumsByCall &&
        this.state.rkSamajamAudioAlbumsByCall.message){
        let details = this.state.rkSamajamAudioAlbumsByCall;
        

        if(details && details.message && details.message.statusDetails && 
            details.message.statusDetails.serviceResponse){
            if(details.message.statusDetails.serviceResponse.albums){
                albums = details.message.statusDetails.serviceResponse.albums;
            }
            if(details.message.statusDetails.serviceResponse.albumdetails){
                this.state.albumdetails = details.message.statusDetails.serviceResponse.albumdetails;
            }
             
        }
    }

    const renderNASearchGrid = ()=>{
        if(recentUploadVideos.length == 0){
          return  <Grid>
                    <Card>
                        <CardActionArea>
                            <ReactPlayer
                            width='200px'
                            height='100px'
                            url="not a valid url"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Not Available
                                </Typography>
                                <Typography component="p">Could not load videos</Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary">
                            NA
                            </Button>
                            <Button size="small" color="primary">
                            @ NA
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
        } 
      }

    const renderNARecentUploadsGrid = ()=>{
        if(recentUploadVideos.length == 0){
          return  <Grid>
                    <Card>
                        <CardActionArea>
                            <ReactPlayer
                            width='200px'
                            height='100px'
                            url="not a valid url"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Not Available
                                </Typography>
                                <Typography component="p">Could not load videos</Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary">
                            NA
                            </Button>
                            <Button size="small" color="primary">
                            @ NA
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
        } 
      }

    // AUDIO SECTION
    const currentTime = getTime(this.state.currentTime);
    const duration = getTime(this.state.duration);

    if(!this.state.isLoading && this.state.rkSamajamAudioMusicByCall &&
        this.state.rkSamajamAudioMusicByCall.message){
        let details = this.state.rkSamajamAudioMusicByCall;
        

        if(details && details.message && details.message.statusDetails && 
            details.message.statusDetails.serviceResponse && 
            details.message.statusDetails.serviceResponse.Items &&
            details.message.statusDetails.serviceResponse.Items.length > 0){

                this.state.playerList = details.message.statusDetails.serviceResponse.Items;

                this.state.playlistTitle = "Playlist (Search): " + this.state.searchCriteria;
                
            }
    }

    if(this.state.playerList.length == 0){
        var playlist = [
            {
                "id": 129,
                "date": "2014-12-11T00:00:00.000Z",
                "title": "Apara karuna-Punniya Orukodi",
                "details": "",
                "tags": "guru",
                "link": "https://d1uxw61g8f505b.cloudfront.net/audio/ramani_bhagavathar/guru/06 Aparakaruna - Punniya Oru kodi.mp3",
                "album": "",
                "icon": "../images/maha_periyava.jpeg"
            },
            {
                "id": 116,
                "date": "2014-12-11T00:00:00.000Z",
                "title": "Yasyasmarana-Bhajare",
                "details": "",
                "tags": "guru",
                "link": "https://d1uxw61g8f505b.cloudfront.net/audio/ramani_bhagavathar/guru/02 Yasyasmarana - Bhajare.mp3",
                "album": "",
                "icon": "../images/maha_periyava.jpeg"
            },
            {
                "id": 128,
                "date": "2014-12-11T00:00:00.000Z",
                "title": "Srikantamiva-Gurumurthy pathamule",
                "details": "",
                "tags": "guru",
                "link": "https://d1uxw61g8f505b.cloudfront.net/audio/ramani_bhagavathar/guru/05 Srikantamiva - Gurumurthy pathamule.mp3",
                "album": "",
                "icon": "../images/maha_periyava.jpeg"
            }
        ].map(item => {
            return (
                <div class="ui list">
                    <div class="item">
                    <img class="ui avatar image" src={item.icon} alt="music images" />
                    <div class="content">
                        <li
                        className="title"
                        key={item.title}
                        onClick={() => this.setState({ currentSong: item.title, currentLink: item.link })}>
                        {item.title.substring(0, 12)} - {item.date.substring(0,10)}
                        </li>
                    </div>
                    </div>
                </div>
            );
        })
    }else{
        
        var playlist = this.state.playerList.map(item => {
            return (
                <div class="ui list">
                    <div class="item">
                    <img class="ui avatar image" src={item.icon} alt="music images" />
                    <div class="content">
                        <li
                        className="title"
                        key={item.title}
                        onClick={() => this.setState({ currentId: item.id, currentSong: item.title, currentLink: item.link })}>
                        {item.title.substring(0, 12)}
                        <Button size="small" color="primary">
                            <FavoriteBorderIcon color="primary" onClick={(e) => {
                                this.handleUserActionclick(item.id, "LIKED", "AUDIO");
                                e.currentTarget.firstChild.style.color="red"
                            }}/>
                        {item.date.substring(0,10)}</Button>
                        </li>
                    </div>
                    </div>
                </div>
            )
        })
    }
    
    return (
      <div>
        <Grid>  
          <div style={{ marginTop: 40, padding: 10}}>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h6">
                   Radhekrishna!!!
                </TypoGraphy>
                <TypoGraphy>
                    <div style={{paddingBottom: "30px"}}>&nbsp;</div>
                </TypoGraphy>
            </Grid>
            <Grid container spacing={40} justify="center">
                    <TextField  id="outlined-basic" label="Search Video(s)" 
                        variant="outlined"  value={this.state.searchCriteria} onChange={this.handleSearchChange}  /> &nbsp;&nbsp;
                    {/* <input type="checkbox" onChange={this.handleCheck.bind(this)} checked={this.state.checked}/> 
                    <p>Include Tags: {this.state.checked}</p> */}
            </Grid>
            <TypoGraphy>
                <div style={{ textAlign: "center", cursor: "pointer", color: "brown", fontFamily: "verdana", fontSize:"60%"}}>
                    <div class="tooltip" style={{cursor: "pointer"}}> <b>Ex: Abhangam, Ashtapadhi, Krishna &nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <span class="tooltiptext">"More: Guru, Oothukadu, Namavali!</span>
                    </div>
                </div>
            </TypoGraphy>
          </div>      

            <div style={{justifyContent: "center"}}>
                    <h3>&nbsp; &nbsp; Albums</h3>
                    <div className="App" >
                    <Carousel  breakPoints={breakPoints}>
                         {
                            albums.map(al => (
                                <div onClick={(e) => this.setAlbumToPlayList(al.album)}>
                                    <TypoGraphy variant="small"><img width='100px' height='50px' src={al.icon}></img></TypoGraphy>
                                    <br/>
                                    <span style={{width:"10px", whiteSpace: "normal", wordWrap: "break-word"}}>{al.album.substring(0,20)}
                                    <br/>
                                    @{al.album_date.substring(0,10)}</span>
                                </div>
                        ))} 
                    </Carousel>
                    <h3>&nbsp; &nbsp; {this.state.playlistTitle} </h3>
                        <div className="ui card main-container">
                            <div className="info-container">
                            {this.state.music === "playing" ? (
                                <div className="current-song">
                                Now Playing {this.state.currentSong}
                                </div>
                            ) : null}
                            {this.state.music === "paused" ? (
                                <div className="current-song">
                                {this.state.currentSong} is paused{" "}
                                </div>
                            ) : null}
                            {this.state.music === "playing" ||
                            this.state.music === "paused" ? (
                                <div>
                                {currentTime} / {duration}
                                </div>
                            ) : (
                                ""
                            )}
                            </div>
                            <div className="button-container">
                            {this.state.music === "paused" && (
                                <button
                                class="ui labeled icon red button"
                                onClick={() => this.setState({ music: "playing" })}
                                >
                                <i class=" large play circle outline icon" />
                                Play
                                </button>
                            )}
                            {this.state.music === "playing" && (
                                <button
                                class="ui labeled icon red button"
                                onClick={() => this.setState({ music: "paused" })}
                                >
                                <i class="large pause circle outline icon" />
                                Pause
                                </button>
                            )}
                            {this.state.music === "playing" ||
                            this.state.music === "paused" ? (
                                <button
                                class="ui labeled icon button"
                                onClick={() => this.setState({ music: "stop" })}
                                >
                                <i class="large stop circle outline icon" />
                                Stop
                                </button>
                            ) : null}
                            </div>

                            <div className="playlist">{playlist}</div>

                            <audio ref={ref => (this.music = ref)} />
                        </div>
                        </div>                      
                
            </div>
        <div>
    
        <Grid>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h6">
                    Search Videos
                </TypoGraphy>  
                <TypoGraphy variant="small">
                    <div class="tooltip" style={{cursor: "pointer"}}> <b>?</b>
                        <span class="tooltiptext">If found, only top few matching rows are displayed. Please type more to narrow down your search results!</span>
                    </div>
                    
                </TypoGraphy>  
            </Grid>   

            <Grid container spacing={40} justify="center"> 
              
                <Carousel  breakPoints={breakPoints}>
                    {
                    videolinks.map(video => (
                    <div  key={video.id}>
                        <Grid item key={video.title}>
                            <Card>
                                <CardActionArea>
                                    <ReactPlayer
                                    width='190px'
                                    height='90px'
                                    controls
                                    url={video.link}
                                    onReady={() => console.log('OnReady CallBack')}
                                    onStart={() => this.handleUserActionclick(video.id, "PLAYED", "VIDEO")}
                                    onPause={() => console.log('OnReady CallBack')}
                                    onEnded={() => console.log('OnReady CallBack')}
                                    onError={() => this.handleUserActionclick(video.id, "ERRED", "VIDEO")}
                                    />
                                </CardActionArea>
                                <div style={{height: 75}}>
                                    <TypoGraphy>
                                        <span  style={{fontWeight: "bold",  cursor: "pointer"}} title={video.details}>{video.title.substring(0, 15)}</span>
                                    </TypoGraphy>
                                    <TypoGraphy>
                                        <span style={{cursor: "pointer"}} title={video.details}>{video.details.substring(0, 15)}...</span>
                                    </TypoGraphy>
                                    <TypoGraphy>
                                        <div>
                                            <Grid container direction="row">
                                                <Grid><Button size="small" color="primary">
                                                    <HoverText>
                                                        <FavoriteBorderIcon color="primary" onClick={(e) => {
                                                            this.handleUserActionclick(video.id, "LIKED", "VIDEO");
                                                            e.currentTarget.firstChild.style.color="red"
                                                        }}/>
                                                    </HoverText>{video.date.substring(0,10)}</Button></Grid>
                                            </Grid>
                                        </div>
                                    </TypoGraphy>
                                </div>
                            </Card>
                        </Grid>
                    </div>
                    ))}    
                </Carousel>
              {renderNASearchGrid()}
            
          </Grid>
          
        </Grid> 
        </div>

        <div>
          <Grid>
            <Grid container spacing={40} justify="center">
                <TypoGraphy variant="h6">
                    Recent Uploads
                </TypoGraphy>
            </Grid>      
                <Grid container spacing={40} justify="center">
                    <Carousel  breakPoints={breakPoints}>
                        {
                        recentUploadVideos.map(video => (
                        <div  key={video.id}>
                            <Grid item key={video.title}>
                                <Card>
                                <CardActionArea>
                                    <ReactPlayer
                                    width='190px'
                                    height='90px'
                                    controls
                                    url={video.link}
                                    onReady={() => console.log('OnReady CallBack')}
                                    onStart={() => this.handleUserActionclick(video.id, "PLAYED", "VIDEO")}
                                    onPause={() => console.log('OnReady CallBack')}
                                    onEnded={() => console.log('OnReady CallBack')}
                                    onError={() => this.handleUserActionclick(video.id, "ERRED", "VIDEO")}
                                    />
                                </CardActionArea>
                                <div style={{height: 75}}>
                                    <TypoGraphy>
                                        <span  style={{fontWeight: "bold",  cursor: "pointer"}} title={video.details}>{video.title.substring(0, 15)}</span>
                                    </TypoGraphy>
                                    <TypoGraphy>
                                        <span style={{cursor: "pointer"}} title={video.details}>{video.details.substring(0, 15)}...</span>
                                    </TypoGraphy>
                                    <TypoGraphy>
                                        <div>
                                            <Grid container direction="row">
                                                <Grid><Button size="small" color="primary">
                                                    <HoverText>
                                                        <FavoriteBorderIcon color="primary" onClick={(e) => {
                                                            this.handleUserActionclick(video.id, "LIKED");
                                                            e.currentTarget.firstChild.style.color="red"
                                                        }}/>
                                                    </HoverText>{video.date.substring(0,10)}</Button></Grid>
                                            </Grid>
                                        </div>
                                    </TypoGraphy>
                                </div>
                                </Card>
                            </Grid>
                        </div>
                        ))}    
                    </Carousel>
                    {renderNARecentUploadsGrid()}                    
                </Grid>
               
            </Grid>             
            </div> 
         
        </Grid> 
        
      </div>
    );
  }
}

export default Downloads;