import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Namasankeerthanam extends Component {

    
    constructor(props){
        super(props)
        this.state = {
            appConstants: window.appConstants
        }
        window.clickLog("load", "Namasankeerthanam");
    }  

    NavUrl(value){
        window.location.href = window.location.href + value;
    }

    openUrl(value){
        window.open(value, false);
      }
      
      
  render() {
    return (
      <div className="pad-top-30 pad-left-page">
          <div class="row">
            <div class="col-sm-7 col-md-5">
                  <div class="">
                  <center><h2>Namasankeerthanam</h2>  </center>                  
                    <br/><br/>

                    <div className="sf_main">
                        <div className="sf_region6">
                            <div className="sf_content">
                                <div styleName="display:block;zoom:1">
                                    <div id="content1">
                                        <div styleName="display:block;">
                                            <div styleName="display:block;">
                                        <div>
                                            <font face="Tahoma, Geneva, sans-serif">
                                                <font size="6">Bhajan<br/></font>
                                                    A Bhajan or kirtan is a Hindu devotional song, often of ancient origin. Great importance is attributed to the singing of bhajans with Bhakti, i.e. loving devotion. "Rasanam Lakshanam Bhajanam" means the act by which we feel closer to either our true self (see jiva, atman), or to God (see Brahman &amp; Ishvara). Acts which are performed for the purpose of pleasing God are also sometimes called bhajan.
                                                </font>
                                        </div>
                                    <p styleName="margin: 0px; padding: 0px;">&nbsp;<br/>
                                    <font face="Tahoma, Geneva, sans-serif">
                                        <strong>BHAJAN</strong> is derived from the word "BHAJANAM", meaning "concentrate on Naama or Roopa of God". Roopa Bhajanam requires one to sit and meditate for a long time on a figure of Ishtadaivam. This is little hard to practice, as some rules have to be followed for good results. Naama Bhajanam is repeating the name of Ishtadaivam. This is very simple and can be done throught the day and night without any effort or rules. Added to it, if the Naama is uttered with Music (Sruti, Raaga and Thaala), it gives a soothing effect and takes the mind to "layam". It, therefore, makes it easy to get one pointed attention and at the same time, it is more pleasant to the ears of the audience, who are also attracted towards this process. It becomes a collective effort (group prayer) which is more effective. Hence, singing in praise of God is the way of life one should adopt in Kaliyuga. It is simple to follow. This should be done with concentration, Bhakthi/Devotion and with one pointed attention.<br/>
                                        <br/>
                                            <font size="4">Types of Bhajans</font><br/>
                                                A look into the past reveals that bhajans as a genre have come a long way; weaving a home for themselves into the core of human hearts. Traditions of bhajan singing, such as Nirguni, Gorakhanathi, Vallabhapanthi, Ashtachhap and Madhura-bhakti, have been formed over the ages. Each sect has their own sets of bhajans and methods of singing.</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">The traditional form of bhajan in south india is known as Sampradya Bhajans.</p>
                                                <p styleName="margin: 0px; padding: 0px;"> </p>
                                                <p>Sampradaya Bhajana or Dakhshina Bharatha Sampradaya Bhajana is the tradition of Bhajans in India and as the name suggests particularly in South India. It's the collection of Kirtanas and Namavalis in specific order. Mainly the order has been evolved by Sri Marudhanallur Sadguru Swamigal, Sri Pudukottai Gopala Krishna Bhagavathar.</p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;The trinity of gurus as far as Sampradaya Bhajans are considered as Bodhendra Swamigal, Sridhara Ayyaval and Marudhanallur Sadguru Swamigal. The sampradaya bhajana paddhati has mainly evolved due to the tradition and efforts of Marudhanallur Sadguru Swamigal.</p>
                                                <p styleName="margin: 0px; padding: 0px;"> </p>
                                                <p>&nbsp;The bhajans are typically rendered in the following order:</p>
                                                <p styleName="margin: 0px; padding: 0px;"> </p>
                                                <div>
                                                    <br/>
                                                        - Dhyana Slokam  <br/>
                                                        - Sangraha Thodaya Mangalam (Thotakam - Beginning with a praise, Mangalam - End)
                                                            Thodaya Mangalam is different from Thotaka Ashtakam of ADI SANKARA <br/>
                                                        - Guru Dhyanam <br/>
                                                        - Guru Abhangs <br/>
                                                        - Sadhu Keertanas (hymns on Sadhus and sants. It can be abhangs also) <br/>
                                                        - <a href="http://en.wikipedia.org/wiki/Jayadeva">Jayadeva Ashtapadi </a> <br/>
                                                        - <a href="http://en.wikipedia.org/wiki/Narayana_Teertha">
                                                            Narayana Teertha Krishna leela Tharangini</a><br/>
                                                    <br/>
                                                </div>
                                                <br/>
                                                <p styleName="margin: 0px; padding: 0px;"> </p>
                                                <p><font face="Tahoma, Geneva, sans-serif">Panchapati (Five songs in Telugu (Bhadrachala Ramadas), Kannada (Sri Purandara dasa), Sanskrit (Sri Sadasiva Brahmendral), Tamil (Sri Gopalakrishna Bharati) and compositions of Sri Tyagaraja, </font><a href="http://en.wikipedia.org/wiki/Meera_Bai" className="mw-redirect"><font face="Tahoma, Geneva, sans-serif">Kabir Das, Meera Bai</font></a><font face="Tahoma, Geneva, sans-serif">, Tulsi das or Surdas</font><font face="Tahoma, Geneva, sans-serif"> Marathi Abhangs on Lord </font><a href="http://en.wikipedia.org/wiki/Panduranga" className="mw-redirect"><font face="Tahoma, Geneva, sans-serif">Panduranga</font></a></p>
                                                <p styleName="margin: 0px; padding: 0px;"> </p>
                                                <p><font face="Tahoma, Geneva, sans-serif">Now comes the turn of Dyana keertanais (hymns on Gods)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">1. Vinayaka</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">2. Saraswati</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">3. Murugan</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">4. Shiva</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">5. Ambika</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">6. Nrusimha</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">7. Ram</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">8. Krishna</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">9. Vishnu or Dashavatara stuti</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">10. Venkatesha</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">11. Vittal or Pandurang (Abhangs)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">12. Lakshmi</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">13. Sita or Radha</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">14. Anjaneya</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">15. Garuda</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">16. Aiyappan</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;1<font face="Tahoma, Geneva, sans-serif">7. Nandikeshwaran</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">18. Chandeeswaran</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;1<font face="Tahoma, Geneva, sans-serif">9. Chaitanya Mahaprabhu</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">20. Sri Gopalakrishna Bhagavatar (or Guru Keertanai)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;</p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">Then comes Pooja Sampradaya Kritis</font></p>
                                                <p styleName="margin: 0px; padding: 0px;"> </p>
                                                <p>&nbsp;1<font face="Tahoma, Geneva, sans-serif">. Baro murare (welcome)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">2. Sharanagata vatsala (request)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">3. Kastoori gana.. (pooja)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;4<font face="Tahoma, Geneva, sans-serif">. Chita juni... (aarati)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">5. Shobane</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">6. Jay Jay aarati...</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">7. kanjadalakshiki..</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">8. Prartana Abhang</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">9. Rajadi rajaya.. (pushpanjali)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">10. Kattiya vachanam (verses from variety of books)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">11. Chatur veda parayan</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">12. Kshetra mahatmiyam (verses on importance of tirta kshetras)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">13. Upacharamu.. (upachara sankeertan)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">14. Vinnappa gadyam (praying to God - shlokas)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;<font face="Tahoma, Geneva, sans-serif">15. Sri Krishna Govinda hare murare.. (naamaavali)</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;</p>
                                                <p><font face="Tahoma, Geneva, sans-serif">Pooja ends here and Divya namam starts.. (deepa pradakshinam - With lighted lamp in the middle considering the lamp as God, bhagavatas will do sankeertan by doing pradakshinas) This equals going around the earth.</font></p>
                                                <p styleName="margin: 0px; padding: 0px;">&nbsp;
                                                    <font face="Tahoma, Geneva, sans-serif">
                                                        Then comes dolotsavam (making God sleep), Anjaneya Keerthanai and Mangalam.</font>
                                                        <font face="Tahoma, Geneva, sans-serif"><br/>
                                                    </font></p>
                                                <div styleName="clear: both;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      
                </div>              
            </div>
           
          </div>
      </div>
    );
  }
}
 
export default Namasankeerthanam;