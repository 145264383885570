window.videolinks = [
    {
     id: "1", 
     title: "Ennappan allava",
     details: "Bengaluru Meenakshi Kalyanam on August 6th & 7th",
     link: "https://www.youtube.com/watch?v=VojCYV4I1bU",
     date: "06/08/2014",
     tags: "namavali"
    },
   
    {
     id: "2", 
     title: "Oothukadu Saptha Gurukeerthanas ",
     details: "@ Nama 2020 - July 15 2020",
     link: "https://www.youtube.com/watch?v=EByS7Cjre5o",
     date: "15/07/2020",
     tags: "guru, oothukadu"
    },
   
    {
     id: "3", 
     title: "Papanasam Sri Sivan Keerthanams ",
     details: "Yuva Bhajan Mela Trust, Palakkad - May 22, 2020",
     link: "https://www.youtube.com/watch?v=ol8w9XIB5IQ",
     date: "22/05/2020",
     tags: "papanasam sivan"
    },
   
    {
     id: "4", 
     title: "Kasthuri Thilakam",
     details: "Sri Chandramoulu Mama residence - Feb 23, 2013",
     link: "https://www.youtube.com/watch?v=2o5fGbVr2kQ",
     date: "13/02/2013",
     tags: "namavali"
    },
   
    {
     id: "5", title: "Ananda Sagara",
     details: "Sri Chandramoulu Mama residence - Feb 23, 2013",
     link: "https://www.youtube.com/watch?v=5dD44k_13pE",
     date: "13/02/2013",
     tags: "namavali"
    },
   
    {
     id: "6", title: "Koti Janma Punya",
     details: "Sankara Hall Samajam Pozhichalur - Jan 19, 2010",
     link: "https://www.youtube.com/watch?v=K_-HlbIp3eU",
     date: "01/19/2010",
     tags: "abhang"
    },
    {
        id: "7", title: "Thodaya mangalam, guru",
        details: "Kansas - Thodaya mangalam, guru Ashtapadhi 1st session",
        link: "https://www.youtube.com/watch?v=UsHwBSz5fjQ",
        date: "30/08/2014",
        tags: "sampradayam, guru"
    }
    ,
    {
        id: "8", title: "Ashtapadhi - Hey nandana",
        details: "Kansas - Ashtapadhi - Hey nandana, vanamali vasudeva",
        link: "https://www.youtube.com/watch?v=UsHwBSz5fjQ",
        date: "30/08/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "9", title: "Ashtapadhi 3rd session",
        details: "Kansas - Ashtapadhi 3rd session",
        link: "https://www.youtube.com/watch?v=l2y80Hp7aoo",
        date: "30/08/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "10", title: "Krishna murali",
        details: "Kansas - Ashtapadhi 3rd session - Krishna murali",
        link: "https://www.youtube.com/watch?v=sYlVM-xrOnE",
        date: "30/08/2014",
        tags: "namavali"
    }
    ,
    {
        id: "11", title: "Oh rama nee namam",
        details: "Kansas - Oh rama nee namam, Jaya janaki , Atma rama ananda ramana",
        link: "https://www.youtube.com/watch?v=WQc43E9I0fA",
        date: "30/08/2014",
        tags: "sampradayam, namavali"
    }
    ,
    {
        id: "12", title: "Chethashri ramam",
        details: " Kansas - Chethashri ramam, Sakala loka nayike, Charanaagatha vathsala, Upacharamu",
        link: "https://www.youtube.com/watch?v=c9wUdeET32U",
        date: "30/08/2014",
        tags: "sampradayam, oothukadu"
    },
    {
        id: "13", title: "Divyanamam",
        details: "Kansas - Divyanamam",
        link: "http://youtu.be/De6GQumTjXQ",
        date: "30/08/2014",
        tags: "divyanamam"
    }
    ,
    {
        id: "14", title: "Divyanamam",
        details: "Kansas - 2nd session - kamala vallabha, krishna krishna, aloka tulasi",
        link: "http://youtu.be/77kl1o29Y8Q",
        date: "30/08/2014",
        tags: "tharangam"
    }
    ,
    {
        id: "15", title: "Murahara, yelello, namacha bazaar",
        details: "Kansas - Murahara, yelello, namacha bazaar",
        link: "https://www.youtube.com/watch?v=ds5HPAB3a6w",
        date: "30/08/2014",
        tags: "abhangam, namavali"
    }
    ,
    {
        id: "16", title: "saasasathvaja sanandam, radhey ",
        details: "Kansas - saasasathvaja sanandam, radhey radhey radhey govinda, radha kalyana vaibogame",
        link: "https://www.youtube.com/watch?v=5xHYyRyGenA",
        date: "30/08/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "17", title: "Allo nere , Suvvi",
        details: "Kansas - Allo nere , Suvvi, &nbsp;chitha juni jayamangalam, Viharathi haririha -jathi",
        link: "http://youtu.be/Incrsyz23So",
        date: "30/08/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "18", title: "Meenakshi Kalyanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=DNFtPxyE-zg&amp;list=PL1sFFfpPjmz8lBjrr-h8wFSd3BIUTCNYT",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "19", title: "Slokam - Thodaya Mangalam (Sivan)",
        details: "Meenakshi Kalyanam -",
        link: "https://www.youtube.com/watch?v=DNFtPxyE-zg",
        date: "22/08/2014",
        tags: "sampradayam"
    }
    ,
    {
        id: "20", title: "Guru Keerthanai",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=KLDSKxo1MOo",
        date: "22/08/2014",
        tags: "guru"
    }
    ,
    {
        id: "21", title: "3 Sivashtapadhi - Kalayanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=alcJHO6MJCU",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "22", title: "04 Sivashtapadhi - Kalayanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=sFj9HKo9pyE",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "23", title: "05 Sivashtapadhi - Kalayanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=-ba6Tt7F9v0",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "24", title: "06 Sivashtapadhi - Kalayanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=nN8OhWQD8O4",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "25", title: "07 Sivashtapadhi - Kalayanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=_v3EAWj6SFY",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "26", title: "08 Sivashtapadhi - Kalayanam",
        details: "Meenakshi Kalyanam",
        link: "https://www.youtube.com/watch?v=_ogLEHp0q-g",
        date: "22/08/2014",
        tags: "meenakshi kalyanam"
    }
    ,
    {
        id: "27", title: "Geetha Govinda Sapthagam",
        details: "Puri - Geetha Govinda Sapthagam - January 2014",
        link: "https://www.youtube.com/playlist?list=PL1sFFfpPjmz_4zQ30Sxtf53NnOohcR9NX",
        date: "16/01/2014",
        tags: "ashtapadhi"
    }
    ,
    {
        id: "28", title: "Introduction",
        details: "Puri - ",
        link: "http://youtu.be/Yf0rMkvL3L8",
        date: "16/01/2014",
        tags: "ashtapadhi"
    }
    ,
    {
        id: "29", title: "Jai jai Ram Uynthathu uynthathu Padmavathy ramanam",
        details: "Puri - ai jai Ram Uynthathu uynthathu Padmavathy ramanam",
        link: "ttps://www.youtube.com/watch?v=ZdwE1pEqBY0",
        date: "16/01/2014",
        tags: "oothukadu"
    }
    ,
    {
        id: "30", title: "Athaagathaaam - Radhey radhey radhey govinda",
        details: "Puri - Athaagathaaam - Radhey radhey radhey govinda",
        link: "https://www.youtube.com/watch?v=gOaUKfHO44c",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "31", title: "Naayathaha sakhi - Athagatha mabhi",
        details: "Puri - Naayathaha sakhi - Athagatha mabhi ",
        link: "https://www.youtube.com/watch?v=lJWnV-RKny0",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "32", title: "Thamatha manmatha kinna",
        details: "Puri - Thamatha manmatha kinna ",
        link: "https://www.youtube.com/watch?v=AfFgyU3p1PE",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "33", title: "Suchiram anunayena",
        details: "Puri - Suchiram anunayena",
        link: "http://youtu.be/EMEa4vnrYrk",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "34", title: "Aaravali",
        details: "Puri - Aaravali",
        link: "http://youtu.be/m-F-nLbOrTE",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "35", title: "Saasasathvaja sanandam Gathavathi Sakhi",
        details: "Puri - Saasasathvaja sanandam Gathavathi Sakh",
        link: "http://youtu.be/nsOh_8_rPqQ",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "36", title: "Athagantham prathi",
        details: "Puri -Athagantham prathi ",
        link: "http://youtu.be/qXISztsoaw0",
        date: "16/01/2014",
        tags: "ashtapadhi, namavali"
    }
    ,
    {
        id: "37", title: "Tharangam",
        details: "Puri - Tharangam ",
        link: "http://youtu.be/p4E2aANyQ2s",
        date: "16/01/2014",
        tags: "tharangam, namavali"
    }
    ,
    {
        id: "38", title: "Ramani Bhavathar - Home Bhajan, Irving Tx - July 2014",
        details: "Ramani Bhavathar - Home Bhajan, Irving Tx - July 2014",
        link: "https://www.youtube.com/playlist?list=PL1sFFfpPjmz_I24Oas94cHabqRJLh9mTz",
        date: "27/07/2014",
        tags: "abhangam, namavali, sampradayam"
    }
    ,
    {
        id: "39", title: "Krishna gana sabha 2012",
        details: "Krishna gana sabha 2012",
        link: "https://www.youtube.com/watch?v=MIFgE04d8Ic",
        date: "24/01/2013",
        tags: "oothukadu"
    }
    ,
    {
        id: "40", title: "Thodaya Mangalam",
        details: "Krishna gana sabha 2012 - Thodaya Mangalam",
        link: "https://www.youtube.com/watch?v=1wYiHwsn2DY",
        date: "24/01/2013",
        tags: "sampradayam"
    }
    ,
    {
        id: "41", title: "Dakshinamurthy",
        details: "Krishna gana sabha 2012 - chitram vatatharo muley - Dakshinasasyam gurum vandhe, Namavali - sambho murthim adhi gurum, Muthakarena - Bajare loka gurum manuja",
        link: "https://www.youtube.com/watch?v=wqiSVIXzA2E",
        date: "24/01/2013",
        tags: "guru, sampradayam"
    }
    ,
    {
        id: "42", title: "Srikantamiva sitharum sachitananda",
        details: "Krishna gana sabha 2012 - Srikantamiva sitharum sachitananda  Yasya unchavruthi samaye - chintayare sadgurum",
        link: "https://www.youtube.com/watch?v=s0JfHEwT-78",
        date: "24/01/2013",
        tags: "guru, sampradayam"
    }
    ,
    {
        id: "43", title: "chintayare Guru matha guru pitha Santha mahantho ",
        details: "Krishna gana sabha 2012 - chintayare Guru matha guru pitha Santha mahantho Pavithradeha vani punya vantham Gyaneshwara mauli ",
        link: "https://www.youtube.com/watch?v=2rlxQzZuyjc",
        date: "24/01/2013",
        tags: "guru, sampradayam"
    }
    ,
    {
        id: "44", title: "Oothukadu - Hari hara bedha Padmavathy ramanam",
        details: "Krishna gana sabha 2012 - Hari hara bedha Namami gyananandha gurum Padmavathy ramanam",
        link: "https://www.youtube.com/watch?v=7uMruvV5bfI",
        date: "24/01/2013",
        tags: "ashtapadhi"
    }
    ,
    {
        id: "45", title: "Padmavathy ramanam  Anekanaari - Haririha mukta",
        details: "Krishna gana sabha 2012 -  Anekanaari - Haririha mukta",
        link: "https://www.youtube.com/watch?v=5dQE0HmrywA",
        date: "24/01/2013",
        tags: "ashtapadhi"
    }
    ,
    {
        id: "46", title: "Yathangri pange Saari bandhane",
        details: "Krishna gana sabha 2012 - Yathangri pange Saari bandhane Navaneetha chora - kayena vacha",
        link: "https://www.youtube.com/watch?v=JoVMpTI_YGk",
        date: "24/01/2013",
        tags: "tharangam"
    }
    ,
    {
        id: "47", title: "Ramani Bhagavathar @ Pozhichalur 2013",
        details: "Ramani Bhagavathar @ Pozhichalur 2013",
        link: "https://www.youtube.com/watch?v=JUD7-CfHtsU",
        date: "27/01/2013",
        tags: "sampradayam"
    }
    ,
    {
        id: "48", title: "omkara chithha - govinda govinda",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=UeLbLRDuAYg",
        date: "12/20/2011",
        tags: "guru, oothukadu"
    }
    ,
    {
        id: "49", title: "meividathu - satyam param dhemahe",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=yr3Ixz48lOA",
        date: "12/20/2011",
        tags: "guru, oothukadu"
    }
    ,
    {
        id: "50", title: "Uynthathu uynthathu",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=_pe_bDACf0U",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "51", title: "Vrajajana kulapaalam - koduthuvaitha punniyavan",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=LIFbFl3GpHc",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "52", title: "Raasa keyli - govinda radhey (namavali)",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=icPcjumGvQs&feature=youtu.be",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "53", title: "vandarntha kuzhal - chora chathurya leela",
        details:"Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=m_gNrU1_D54",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "54", title: "sonnathai kel kanna",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=P9WusqNJ960",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "55", title: "Maninoopura thaari",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=jogewP_cwVo&feature=youtu.be",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "56", title: "Petrai thavam - ninringu arulkaatu",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=jCzMXVINLAE&feature=youtu.be",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "57", title: "Nanmaiyaum selvamum part 1 (slokam)",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011",
        link: "https://www.youtube.com/watch?v=Vr6t-9ykBwk",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "58", title: "Dasarathey daya - sonnal mangalam",
        details: "Naadhabrahmam Music Festival@ Dakshinamurthy Auditorium - Oothukadu 12/20/2011 - nanmaiyaum selvamum part 2 - Dasarathey daya - sonnal mangalam - kayenavacha",
        link: "https://www.youtube.com/watch?v=3NRFetN1Omc&feature=youtu.be",
        date: "12/20/2011",
        tags: "oothukadu"
    }
    ,
    {
        id: "59", title: "Guru Maatha maname",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=2Ax6tpp8J0A&feature=related",
        date: "23/07/2011",
        tags: "abhangam"
    }
    ,
    {
        id: "60", title: "Kayanai akayaniaakhi-nimmathi adainthen",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=1HdkExQpjus&feature=mfu_in_order&list=UL",
        date: "23/07/2011",
        tags: "guru"
    }
    ,
    {
        id: "61", title: "guru krupa anjan_maname prema_om guru natha",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=gdxA3wqHXwQ&feature=related",
        date: "23/07/2011",
        tags: "guru, abhangam, namavali"
    }
    ,
    {
        id: "62", title: "azhumthorum anaikkum yenna punniam",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=TqAGiSGYR9U&feature=related",
        date: "23/07/2011",
        tags: "guru"
    }
    ,
    {
        id: "63", title: "saantha mahantho Udhaara thumi santha gyaneshwara mavuli",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=0EWo_iXcTfs&feature=related",
        date: "23/07/2011",
        tags: "guru, abhangam"
    }
    ,
    {
        id: "64", title: "radhey radhey brundavana",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=lNACoLTPkFU&feature=related",
        date: "23/07/2011",
        tags: "namavali"
    }
    ,
    {
        id: "65", title: "srimukha saari vittal rakhumayi",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=INluOyplhwk&feature=related",
        date: "23/07/2011",
        tags: "abhangam"
    }
    ,
    {
        id: "66", title: "Swami maje brahma chari",
        details: "Namasankeerthanam @ Dakshina Pandaripuram, Govindapuram",
        link: "https://www.youtube.com/watch?v=BkPiJ4JE_7M",
        date: "23/07/2011",
        tags: "abhangam"
    }
    ,
    {
        id: "67", title: "Jai jai ram krishna",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=SYhcTtjpiSo",
        date: "05/27/2011",
        tags: "namavali, abhangam"
    },
    {
        id: "68", title: "Sarvesaam ashramaanam - Bhajare manasa",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=S7KV6SMz1wQ",
        date: "05/27/2011",
        tags: "guru, sampradayam"
    },
    {
        id: "69", title: "sandana sarchitha (4th asthapadi) kasthuri thilagam namavali ",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=Zyj-bT68A74",
        date: "05/27/2011",
        tags: "ashtapadhi, namavali"
    },
    {
        id: "70", title: "avaso priya saki - sthanavihithamapi (9th ashtapadi)",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=tj0vJypoDy4",
        date: "05/27/2011",
        tags: "ashtapadhi, namavali"
    },
    {
        id: "71", title: "Athranthare - charuseeley - priya radhey",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=DWIUxebsEJI",
        date: "05/27/2011",
        tags: "ashtapadhi, namavali"
    },
    {
        id: "72", title: " yathangri pange - kankshey thava - govinda jai (",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=G88N9qsjG_c",
        date: "05/27/2011",
        tags: "ashtapadhi, namavali"
    },
    {
        id: "73", title: " nanmayum selvamum - dasaratha rama govinda - sri ramacharanam",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=FPOfZEEh8WI",
        date: "05/27/2011",
        tags: "purandaradasa"
    },
    {
        id: "74", title: "dhyanavu kritha yugathi slokam",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=xuaFRZEggMo",
        date: "05/27/2011",
        tags: "purandaradasa"
    },
    {
        id: "75", title: "Devaki Nandana Nandamukunda",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=1KX0ORhOc7I",
        date: "05/27/2011",
        tags: "purandaradasa"
    },
    {
        id: "76", title: "Baro murarey - kasthurigana chaara - sobane",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=Fxq2QYUh9t0",
        date: "05/27/2011",
        tags: "poojai"
    },
    {
        id: "77", title: "Vedaa Vedaa hoh pandari",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=CTv9XFb_-gA",
        date: "05/27/2011",
        tags: "abhang"
    },
    {
        id: "78", title: "Rajathi - upachaara keerthanai (karur mama)",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=DeBIeyNiMq0",
        date: "05/27/2011",
        tags: "poojai"
    },
    {
        id: "79", title: "maje manuraatha (sruthi kumar)",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=_KH7BwXutL0",
        date: "05/27/2011",
        tags: "seetha kalyanam"
    },
    {
        id: "80", title: "jaya mahisasura marthini",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=Mp52xxgtdEA",
        date: "05/27/2011",
        tags: "seetha kalyanam"
    },
    {
        id: "81", title: "rama krishna bhajan",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=noslcZapizk",
        date: "05/27/2011",
        tags: "namavali"
    },
    {
        id: "82", title: "navavimamanakamam - ramam parinaya - rama kalyanam ashtapadhi - seetha kalyana",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=_4mk9JzsB-M",
        date: "05/27/2011",
        tags: "ashtapadhi, seetha kalyanam"
    },
    {
        id: "83", title: "Suvvi rama - chithachuni - viharathi haririha - dehi mangalam",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=fTRBWssCs-k",
        date: "05/27/2011",
        tags: "poojai, seetha kalyanam"
    },
    {
        id: "84", title: "dasaratha thanayudu rama - yagnopavitham",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=-7lBsNKrGIg",
        date: "05/27/2011",
        tags: "seetha kalyanam"
    },
    {
        id: "85", title: "Raghu nandana (namavali)",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=FAvTgwlxl3Y",
        date: "05/27/2011",
        tags: "namavali"
    },
    {
        id: "86", title: "seetha kalyanam Othividuthal - sathayai",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=kj5xUl0NVv0",
        date: "05/27/2011",
        tags: "seetha kalyanam"
    },
    {
        id: "87", title: "Seethapathi ramachandra ki - rama raghuvara namavali",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=HlnlIhwRtp4",
        date: "05/27/2011",
        tags: "abhangam"
    },
    {
        id: "88", title: "churnikai slokam - mangalya tharanam slokams -seetha rama",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=dWazoxWAQNg",
        date: "05/27/2011",
        tags: "seetha kalyanam"
    },
    {
        id: "89", title: "hey dayalu",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011",
        link: "https://www.youtube.com/watch?v=w2SQLOZK8rk&feature=related",
        date: "05/27/2011",
        tags: "abhangam"
    },
    {
        id: "90", title: "swami maja - anjaneecha sutha",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011 - Anjaneya Jayanthi",
        link: "https://www.youtube.com/watch?v=KApBU45l8Bc",
        date: "05/27/2011",
        tags: "abhangam"
    },
    {
        id: "91", title: "karpagame kanparaai - bhavamaana",
        details: "Seetha Kalyanam @ Sri Kunjumani Bhagavathar's home - 05/27/2011 - Anjaneya Jayanthi",
        link: "https://www.youtube.com/watch?v=3DiTomj3kYc",
        date: "05/27/2011",
        tags: "keerthanai"
    },
    {
        id: "92", title: "Shemam Kuru Gopala.",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=OTIULOSu7dI",
        date: "01/18/2010",
        tags: "tharangam"
    },
    {
        id: "93", title: "Guru purandhara dasa",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=SE7YxwrIJsw",
        date: "01/18/2010",
        tags: "guru, purandaradasa"
    },
    {
        id: "94", title: "Sadguru vanchoni",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=agiM945YXVs",
        date: "01/18/2010",
        tags: "abhangam"
    },
    {
        id: "95", title: "Govinda Madhava",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=_aGUHyiUeVs",
        date: "01/18/2010",
        tags: "namavali"
    },
    {
        id: "96", title: "Koti Janma Punya",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=K_-HlbIp3eU",
        date: "01/18/2010",
        tags: "abhangam"
    },
    {
        id: "97", title: "yasyangam bhajare guru charanam",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=CaZGSiSZeMM",
        date: "01/18/2010",
        tags: "guru, sampradayam"
    },
    {
        id: "98", title: "Ashtapadhi - slokams",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=BIwjaVYGdwI",
        date: "01/18/2010",
        tags: "ashtapadhi"
    },
    {
        id: "99", title: "Ahamiha nivasami - Ashtapadhi 10",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=W0VUl8ir2cM",
        date: "01/18/2010",
        tags: "ashtapadhi"
    },
    {
        id: "100", title: "Adidum perumal Thandavam - Namavali",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=q3HcS4H6o9s",
        date: "01/18/2010",
        tags: "namavali"
    },
    {
        id: "101", title: "Srikantamiva pasvantham - Sridhara venkatesam",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=L1NTA0EZfFQ",
        date: "01/18/2010",
        tags: "guru, sampradayam"
    },
    {
        id: "102", title: "Srimad bodhendra desikendra namosthuthey - Bodhendra guru bajeham",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=c3aM8FQpbZw",
        date: "01/18/2010",
        tags: "guru, sampradayam"
    },
    {
        id: "103", title: "Varumai Agatri",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=t2J6Lb9pA-M",
        date: "01/18/2010",
        tags: "anjaneya"
    },
    {
        id: "104", title: "Bavamana",
        details: "Samajam 01/18/2010",
        link: "https://www.youtube.com/watch?v=n6dYmNJQLsY",
        date: "01/18/2010",
        tags: "sampradayam"
    },
    {
        id: "105", title: "NAMASANKEERTHANA MELA-2009 by Kunnakkudy School",
        details: "NAMASANKEERTHANA MELA-2009 by Kunnakkudy School",
        link: "https://www.youtube.com/watch?v=xRxTg5Ixcao",
        date: "05/19/2009",
        tags: "oothukadu"
    },
    {
        id: "106", title: "Mata Ramo matpita ramachandra - Jyachi vamsi kuladarma",
        details: "Samajam 24/06/2012",
        link: "https://www.youtube.com/watch?v=qZixcbNiYA0",
        date: "24/06/2012",
        tags: "abhangam"
    }
]
