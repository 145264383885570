import React, { Component } from "react";
import "../js/appConstants.js";
import TypoGraphy from '@material-ui/core/Typography'
import { Grid, Paper, Typography, List, ListItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';

class Home extends Component {

constructor(props){
        super(props)
        this.state = {
            appConstants: window.appConstants
        }
        window.clickLog("load", "homepage");
    }  
    NavUrl(value){
        window.location.href = window.location.href + value;
    }
    
  render() {
   
    return (
        <ThemeProvider theme={theme}>
            <div>
                <div style={{ marginTop: 40, padding: 30 }}>
                    <Grid container spacing={40} justify="center">
                        <TypoGraphy variant="h6">
                            RadheKrishna Welcome to RadhaKrishna Sankeerthana Samajam!!!
                        </TypoGraphy>
                    </Grid>
                    
                    <Grid container spacing={40} justify="left">
                    <TypoGraphy>
                            RadhaKrishna Sankeerthana Samajam is a happening place for Namasankeerthanams & Bhajans.  With the divine blessings of His Holiness Shri Chandrasekarendra Saraswati Swamigal, the samajam, a non-profit organisation was started in the year 1983 in the house of Shri Papanasam Ramani Bhagavathar in Pozhichalur, a suburb of Chennai.
                        </TypoGraphy>
                        <TypoGraphy>
                            His Holiness Shri Jayendra Saraswathi Swamigal and His Holiness Shri Vijayendra Saraswathi Swamigal visited the samajam when it was under construction and named it "Shri Sankara Hall".
                        </TypoGraphy>
                        <TypoGraphy style={{ marginTop: 20, padding: 10 }}>
                            <Card >
                                <CardMedia>
                                <img src="/images/radha-krishna.jpg" alt="recipe thumbnail"/>
                                </CardMedia>
                            </Card>
                        </TypoGraphy>
                        <TypoGraphy style={{ marginTop: 20, padding: 10 }}>
                            <List>* Weekly Bhajans</List>
                            <TypoGraphy>
                                <ListItem>- Ekadasi Bhajans</ListItem>
                                <ListItem>- Kaarthika Pournami Bhajans</ListItem>
                                <ListItem>- Radha Kalyanam</ListItem>
                                <ListItem>- Seetha kalyanam</ListItem>
                                <ListItem>- Meenakshi Kalyanam</ListItem>
                                <ListItem>- Abhanga Bhajans</ListItem>
                                <ListItem>- Oothukadu Kritis</ListItem>
                                <ListItem>- Pavvalippu</ListItem>
                                <ListItem>- Dolothsavam</ListItem>
                            </TypoGraphy>
                        </TypoGraphy>
                    
                        <TypoGraphy style={{ marginTop: 20, padding: 10 }}>
                        </TypoGraphy>
                        <TypoGraphy>
                            Radhekrishna!!!  Sairam!!!
                        </TypoGraphy>
                        <TypoGraphy style={{ marginTop: 20, padding: 10 }}>
                        </TypoGraphy>
                        <TypoGraphy>
                            For Sathsangam details:
                        </TypoGraphy>
                        <TypoGraphy>
                            Please visit <a href="#/ContactUs">Contact Us</a>
                        </TypoGraphy>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
        );  
    }
}
 
export default Home;